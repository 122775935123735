import { Color, Material, MeshPhysicalMaterial, MeshStandardMaterial } from "three";
import { useScene } from "../context/SceneContext";
import { useSnapshot } from "valtio"

export function RealityMaterial({ material }: {material: MeshStandardMaterial | MeshPhysicalMaterial}) {  
  const { graph, settings } = useScene();
  const materialsState = useSnapshot(graph.materials);
  const { environmentIntensity } = useSnapshot(settings)

  const props = materialsState[material.name];
   
  const mergedProps = {
    ...material,
    ...props,
    color: !!props?.color ? (new Color(props.color)).convertSRGBToLinear() : material.color,
    envMapIntensity: environmentIntensity
  }
  if (material instanceof MeshPhysicalMaterial) {    
    return <meshPhysicalMaterial {...mergedProps} />;
  } else {
    return <meshStandardMaterial {...mergedProps} />;
  }
}
