import { FunctionComponent, HTMLAttributes } from "react";

interface AnnotationMarkerProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
}

const AnnotationMarker: FunctionComponent<AnnotationMarkerProps> = ({
  label,
  ...props
}) => {
  return (
    <div
      style={{
        width: "28px",
        height: "28px",
        background: "rgba(0,0,0,0.3)",
        borderRadius: "999em",
        color: "white",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "sans-serif",
        fontSize: "12px",
        border: "2px solid rgba(255,255,255,0.7)",
        userSelect: "none",
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        cursor: "pointer",
      }}
      {...props}
    >
      {label ? (
        label
      ) : (
        <div
          style={{
            borderRadius: "999em",
            background: "rgba(255,255,255, 0.9)",
            width: "12px",
            height: "12px",
          }}
        />
      )}
    </div>
  );
};

export { AnnotationMarker };
