import { BasicAnnotation, AnnotationProps } from "./BasicAnnotation";

type TextAnnotationProps = {
  title: string;
  markerLabel?: string;
  description?: string;
} & AnnotationProps;

export function TextAnnotation({
  title,
  description,
  markerLabel,
  ...props
}: TextAnnotationProps) {
  return (
    <BasicAnnotation markerLabel={markerLabel} {...props}>
      <div
        style={{
          background: "rgba(0,0,0,0.7)",
          color: "white",
          padding: "1.4em",
          borderRadius: "0.6em",
          fontFamily: "sans-serif",
          width: "240px"
        }}
      >
        <span
          style={{
            margin: "0",
            fontSize: "1.3em",
            fontWeight: "bold",
            lineHeight: "1.3em",
            display: "block",
          }}
        >
          {title}
        </span>
        {description && (
          <p
            style={{
              margin: "0",
              marginTop: "1em",
              fontSize: "1em",
              lineHeight: "1.4em"
            }}
          >
            {description}
          </p>
        )}
      </div>
    </BasicAnnotation>
  );
}
