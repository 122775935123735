import { useAnimations } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import { useEffect } from "react";
import { Vector2Tuple, Vector3Tuple } from "three";
import { useAsset } from "use-asset";
import { ref } from "valtio";
import { useEvents } from "../context/EventContext";
import { useScene } from "../context/SceneContext";
import { decryptData } from "../hooks/useCrypto";
import { useGLTFParser } from "../hooks/useGLTFParser";
import { RealityNode } from "./RealityNode";

export interface ClickIntersection {
  intersections: {
    object: string;
    distance: number;
    point: Vector3Tuple;
    uv?: Vector2Tuple;
  }[];
  ray: {
    origin: Vector3Tuple,
    direction: Vector3Tuple
  }
}

type Props = {
  modelURL: string;
  onModelLoaded: () => void;
  onStartedLoading?: () => void;
  encrypted: boolean;
};

async function loadModelData(url: string, encrypted: boolean) {
  const res = await fetch(url);    
  const buff = await res.arrayBuffer();
  if (encrypted) {
    const decrypted = await decryptData(buff, url.split("/")[url.split("/").length - 2])
    return decrypted;
  } else {
    return new Uint8Array(buff);
  }
}

export default function RealityModel({ modelURL, onModelLoaded, onStartedLoading, encrypted }: Props) {
  
  useEffect(() => {
    onStartedLoading?.()
  }, []);

  const data = useAsset(loadModelData, modelURL, encrypted);  
    
  const { scene, animations } = useGLTFParser(data.buffer, modelURL);  
  const { actions, names } = useAnimations(animations, scene)  
  const { state, graph } = useScene();
  const eventEmitter = useEvents();

  useEffect(() => {
    graph.animations = {
      names: names,
      actions: ref(actions)
    }
  }, [names, actions])

  useEffect(() => {
    if (onModelLoaded) {
      onModelLoaded();
    }
    state.modelLoaded = true;
  }, []);

  const onClick = (event: ThreeEvent<MouseEvent>) => {    
    // only closest mesh
    event.stopPropagation();
    // convert event data
    const eventData: ClickIntersection = {
      intersections: event.intersections.map((intersection) => {
        return {
          distance: intersection.distance,
          point: intersection.point.toArray(),
          uv: intersection.uv?.toArray(),
          object: intersection.object.name,
        };
      }),
      ray: {
        origin: event.ray.origin.toArray(),
        direction: event.ray.direction.toArray(),
      }
    };
    eventEmitter.emit("clickIntersection", eventData);
  };

  return (
    <group onClick={onClick}>
      <RealityNode object={scene} />
    </group>
  );
}
