import { useEvents } from "../context/EventContext";

const isARQuickLookSupported = () => {
  return document.createElement("a").relList.supports("ar");
};

async function isWebXRSupported(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if ("xr" in navigator) {
      // @ts-ignore
      navigator.xr
        .isSessionSupported("immersive-ar")
        .then((supported) => {
          resolve(supported);
        })
        .catch(() => {
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
}

export function useAR() {
  const ee = useEvents();

  const triggerAR = (onNotSupported?: () => void) => {
    if (isARQuickLookSupported()) {
      ee.emit("openUSDZ");
    } else {
      const checkWebXRSupport = isWebXRSupported();
      checkWebXRSupport.then((supported) => {
        if (supported) {
          ee.emit("enter-webxr");
        } else {
          onNotSupported?.();
        }
      });
    }
  };

  return { triggerAR };
}
