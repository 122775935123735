import GUI, { Controller } from "lil-gui";
import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  isConfigurationSelectParameter,
  isConfigurationNumberParameter,
  isConfigurationToggleParameter,
  isConfigurationTextParameter
} from "../models/ConfigurationParameter";
import { useConfiguration } from "../context/ConfigurationContext";
import { subscribeKey } from "valtio/utils";
import { snapshot } from "valtio/vanilla";
import * as ReactDOM from "react-dom";
import { useThree } from "@react-three/fiber";
import { useSnapshot } from "valtio";

type ConfigurationGUIProps = {
  enabled?: boolean;
};

// build UI from configuration schema

const ConfigurationGUI: FunctionComponent<ConfigurationGUIProps> = ({
  enabled = true,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const { configurationSchema, configuration, updateConfiguration } =
    useConfiguration();

  const [guiEnabled, setGuiEnabled] = useState(enabled);

  useEffect(() => {
    setGuiEnabled(enabled);
  }, [enabled]);

  useEffect(() => {
    const gui = new GUI({ autoPlace: false, injectStyles: false });
    gui.title("Configurator");

    let unsubscribes: Array<() => void> = [];

    configurationSchema.parameters.map((parameter) => {
      let guiController: Controller | undefined = undefined;
      if (isConfigurationSelectParameter(parameter)) {
        const options = {};
        parameter.options.forEach((option) => {
          options[option.name] = option.name;
        });

        guiController = gui?.add(
          configuration.parameters,
          parameter.name,
          options
        );
      }

      if (isConfigurationNumberParameter(parameter)) {
        guiController = gui?.add(
          configuration.parameters,
          parameter.name,
          parameter.settings.min,
          parameter.settings.max,
          parameter.settings.step
        );
      }

      if (isConfigurationToggleParameter(parameter)) {
        guiController = gui?.add(configuration.parameters, parameter.name);
      }
      
      if (isConfigurationTextParameter(parameter)) {
        guiController = gui?.add(configuration.parameters, parameter.name);
      }

      guiController?.name(parameter.name);
      const unsubscribe = subscribeKey(
        configuration.parameters,
        parameter.name,
        () => {
          guiController?.setValue(configuration.parameters[parameter.name]);
        }
      );
      unsubscribes.push(unsubscribe);
    });

    container.current?.appendChild(gui.domElement);

    return () => {
      gui.reset();
      gui.domElement.parentElement?.removeChild(gui.domElement);

      unsubscribes.forEach((unsubscribe) => {
        unsubscribe();
      });
      unsubscribes = [];
    };
  }, [configurationSchema]);

  return (
    <div>
      <ConfiguratorGUIStyle />
      <div
        style={{
          position: "absolute",
          right: "40px",
          top: "0px",
          display: guiEnabled ? "block" : "none",
        }}
        ref={container}
      />
    </div>
  );
};

export const ConfiguratorGUIStyle = () => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
    .lil-gui {
      font-family: var(--font-family);
      font-size: var(--font-size);
      line-height: 1;
      font-weight: normal;
      font-style: normal;
      text-align: left;
      background-color: var(--background-color);
      color: var(--text-color);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      --background-color: #1f1f1f;
      --text-color: #ebebeb;
      --title-background-color: #111111;
      --title-text-color: #ebebeb;
      --widget-color: #424242;
      --hover-color: #4f4f4f;
      --focus-color: #595959;
      --number-color: #2cc9ff;
      --string-color: #a2db3c;
      --font-size: 11px;
      --input-font-size: 11px;
      --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif;
      --font-family-mono: Menlo, Monaco, Consolas, "Droid Sans Mono", monospace;
      --padding: 4px;
      --spacing: 4px;
      --widget-height: 20px;
      --name-width: 45%;
      --slider-knob-width: 2px;
      --slider-input-width: 27%;
      --color-input-width: 27%;
      --slider-input-min-width: 45px;
      --color-input-min-width: 45px;
      --folder-indent: 7px;
      --widget-padding: 0 0 0 3px;
      --widget-border-radius: 2px;
      --checkbox-size: calc(0.75 * var(--widget-height));
      --scrollbar-width: 5px;
    }
    .lil-gui, .lil-gui * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    .lil-gui.root {
      width: var(--width, 245px);
      display: flex;
      flex-direction: column;
    }
    .lil-gui.root > .title {
      background: var(--title-background-color);
      color: var(--title-text-color);
    }
    .lil-gui.root > .children {
      overflow-x: hidden;
      overflow-y: auto;
    }
    .lil-gui.root > .children::-webkit-scrollbar {
      width: var(--scrollbar-width);
      height: var(--scrollbar-width);
      background: var(--background-color);
    }
    .lil-gui.root > .children::-webkit-scrollbar-thumb {
      border-radius: var(--scrollbar-width);
      background: var(--focus-color);
    }
    @media (pointer: coarse) {
      .lil-gui.allow-touch-styles {
        --widget-height: 28px;
        --padding: 6px;
        --spacing: 6px;
        --font-size: 13px;
        --input-font-size: 16px;
        --folder-indent: 10px;
        --widget-padding: 0 0 0 3px;
        --scrollbar-width: 7px;
        --slider-input-min-width: 50px;
        --color-input-min-width: 65px;
      }
    }
    .lil-gui.force-touch-styles {
      --widget-height: 28px;
      --padding: 6px;
      --spacing: 6px;
      --font-size: 13px;
      --input-font-size: 16px;
      --folder-indent: 10px;
      --widget-padding: 0 0 0 3px;
      --scrollbar-width: 7px;
      --slider-input-min-width: 50px;
      --color-input-min-width: 65px;
    }
    .lil-gui.autoPlace {
      max-height: 100%;
      position: fixed;
      top: 0;
      right: 15px;
      z-index: 1001;
    }
    
    .lil-gui .controller {
      display: flex;
      align-items: center;
      padding: 0 var(--padding);
      margin: var(--spacing) 0;
    }
    .lil-gui .controller.disabled {
      opacity: 0.5;
    }
    .lil-gui .controller.disabled, .lil-gui .controller.disabled * {
      pointer-events: none !important;
    }
    .lil-gui .controller > .name {
      min-width: var(--name-width);
      flex-shrink: 0;
      white-space: pre;
      padding-right: var(--spacing);
      line-height: var(--widget-height);
    }
    .lil-gui .controller .widget {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      min-height: var(--widget-height);
    }
    .lil-gui .controller.string input {
      color: var(--string-color);
    }
    .lil-gui .controller.boolean .widget {
      cursor: pointer;
    }
    .lil-gui .controller.color .display {
      width: 100%;
      height: var(--widget-height);
      border-radius: var(--widget-border-radius);
      position: relative;
    }
    @media (hover: hover) {
      .lil-gui .controller.color .display:hover:before {
        content: " ";
        display: block;
        position: absolute;
        border-radius: var(--widget-border-radius);
        border: 1px solid #fff9;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
    .lil-gui .controller.color input[type=color] {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    .lil-gui .controller.color input[type=text] {
      margin-left: var(--spacing);
      font-family: var(--font-family-mono);
      min-width: var(--color-input-min-width);
      width: var(--color-input-width);
      flex-shrink: 0;
    }
    .lil-gui .controller.option select {
      opacity: 0;
      position: absolute;
      width: 100%;
      max-width: 100%;
    }
    .lil-gui .controller.option .display {
      position: relative;
      pointer-events: none;
      border-radius: var(--widget-border-radius);
      height: var(--widget-height);
      line-height: var(--widget-height);
      max-width: 100%;
      overflow: hidden;
      word-break: break-all;
      padding-left: 0.55em;
      padding-right: 1.75em;
      background: var(--widget-color);
    }
    @media (hover: hover) {
      .lil-gui .controller.option .display.focus {
        background: var(--focus-color);
      }
    }
    .lil-gui .controller.option .display.active {
      background: var(--focus-color);
    }
    .lil-gui .controller.option .display:after {
      font-family: "lil-gui";
      content: "↕";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding-right: 0.375em;
    }
    .lil-gui .controller.option .widget,
    .lil-gui .controller.option select {
      cursor: pointer;
    }
    @media (hover: hover) {
      .lil-gui .controller.option .widget:hover .display {
        background: var(--hover-color);
      }
    }
    .lil-gui .controller.number input {
      color: var(--number-color);
    }
    .lil-gui .controller.number.hasSlider input {
      margin-left: var(--spacing);
      width: var(--slider-input-width);
      min-width: var(--slider-input-min-width);
      flex-shrink: 0;
    }
    .lil-gui .controller.number .slider {
      width: 100%;
      height: var(--widget-height);
      background-color: var(--widget-color);
      border-radius: var(--widget-border-radius);
      padding-right: var(--slider-knob-width);
      overflow: hidden;
      cursor: ew-resize;
      touch-action: pan-y;
    }
    @media (hover: hover) {
      .lil-gui .controller.number .slider:hover {
        background-color: var(--hover-color);
      }
    }
    .lil-gui .controller.number .slider.active {
      background-color: var(--focus-color);
    }
    .lil-gui .controller.number .slider.active .fill {
      opacity: 0.95;
    }
    .lil-gui .controller.number .fill {
      height: 100%;
      border-right: var(--slider-knob-width) solid var(--number-color);
      box-sizing: content-box;
    }
    
    .lil-gui-dragging .lil-gui {
      --hover-color: var(--widget-color);
    }
    .lil-gui-dragging * {
      cursor: ew-resize !important;
    }
    
    .lil-gui-dragging.lil-gui-vertical * {
      cursor: ns-resize !important;
    }
    
    .lil-gui .title {
      --title-height: calc(var(--widget-height) + var(--spacing) * 1.25);
      height: var(--title-height);
      line-height: calc(var(--title-height) - 4px);
      font-weight: 600;
      padding: 0 var(--padding);
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
      outline: none;
      text-decoration-skip: objects;
    }
    .lil-gui .title:before {
      font-family: "lil-gui";
      content: "▾";
      padding-right: 2px;
      display: inline-block;
    }
    .lil-gui .title:active {
      background: var(--title-background-color);
      opacity: 0.75;
    }
    @media (hover: hover) {
      body:not(.lil-gui-dragging) .lil-gui .title:hover {
        background: var(--title-background-color);
        opacity: 0.85;
      }
      .lil-gui .title:focus {
        text-decoration: underline var(--focus-color);
      }
    }
    .lil-gui.root > .title:focus {
      text-decoration: none !important;
    }
    .lil-gui.closed > .title:before {
      content: "▸";
    }
    .lil-gui.closed > .children {
      transform: translateY(-7px);
      opacity: 0;
    }
    .lil-gui.closed:not(.transition) > .children {
      display: none;
    }
    .lil-gui.transition > .children {
      transition-duration: 300ms;
      transition-property: height, opacity, transform;
      transition-timing-function: cubic-bezier(0.2, 0.6, 0.35, 1);
      overflow: hidden;
      pointer-events: none;
    }
    .lil-gui .children:empty:before {
      content: "Empty";
      padding: 0 var(--padding);
      margin: var(--spacing) 0;
      display: block;
      height: var(--widget-height);
      font-style: italic;
      line-height: var(--widget-height);
      opacity: 0.5;
    }
    .lil-gui.root > .children > .lil-gui > .title {
      border: 0 solid var(--widget-color);
      border-width: 1px 0;
      transition: border-color 300ms;
    }
    .lil-gui.root > .children > .lil-gui.closed > .title {
      border-bottom-color: transparent;
    }
    .lil-gui + .controller {
      border-top: 1px solid var(--widget-color);
      margin-top: 0;
      padding-top: var(--spacing);
    }
    .lil-gui .lil-gui .lil-gui > .title {
      border: none;
    }
    .lil-gui .lil-gui .lil-gui > .children {
      border: none;
      margin-left: var(--folder-indent);
      border-left: 2px solid var(--widget-color);
    }
    .lil-gui .lil-gui .controller {
      border: none;
    }
    
    .lil-gui input {
      -webkit-tap-highlight-color: transparent;
      border: 0;
      outline: none;
      font-family: var(--font-family);
      font-size: var(--input-font-size);
      border-radius: var(--widget-border-radius);
      height: var(--widget-height);
      background: var(--widget-color);
      color: var(--text-color);
      width: 100%;
    }
    @media (hover: hover) {
      .lil-gui input:hover {
        background: var(--hover-color);
      }
      .lil-gui input:active {
        background: var(--focus-color);
      }
    }
    .lil-gui input:disabled {
      opacity: 1;
    }
    .lil-gui input[type=text],
    .lil-gui input[type=number] {
      padding: var(--widget-padding);
    }
    .lil-gui input[type=text]:focus,
    .lil-gui input[type=number]:focus {
      background: var(--focus-color);
    }
    .lil-gui input::-webkit-outer-spin-button,
    .lil-gui input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .lil-gui input[type=number] {
      -moz-appearance: textfield;
    }
    .lil-gui input[type=checkbox] {
      appearance: none;
      -webkit-appearance: none;
      height: var(--checkbox-size);
      width: var(--checkbox-size);
      border-radius: var(--widget-border-radius);
      text-align: center;
      cursor: pointer;
    }
    .lil-gui input[type=checkbox]:checked:before {
      font-family: "lil-gui";
      content: "✓";
      font-size: var(--checkbox-size);
      line-height: var(--checkbox-size);
    }
    @media (hover: hover) {
      .lil-gui input[type=checkbox]:focus {
        box-shadow: inset 0 0 0 1px var(--focus-color);
      }
    }
    .lil-gui button {
      -webkit-tap-highlight-color: transparent;
      outline: none;
      cursor: pointer;
      font-family: var(--font-family);
      font-size: var(--font-size);
      color: var(--text-color);
      width: 100%;
      height: var(--widget-height);
      text-transform: none;
      background: var(--widget-color);
      border-radius: var(--widget-border-radius);
      border: 1px solid var(--widget-color);
      text-align: center;
      line-height: calc(var(--widget-height) - 4px);
    }
    @media (hover: hover) {
      .lil-gui button:hover {
        background: var(--hover-color);
        border-color: var(--hover-color);
      }
      .lil-gui button:focus {
        border-color: var(--focus-color);
      }
    }
    .lil-gui button:active {
      background: var(--focus-color);
    }
    
    @font-face {
      font-family: "lil-gui";
      src: url("data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAUsAAsAAAAACJwAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAAH4AAADAImwmYE9TLzIAAAGIAAAAPwAAAGBKqH5SY21hcAAAAcgAAAD0AAACrukyyJBnbHlmAAACvAAAAF8AAACEIZpWH2hlYWQAAAMcAAAAJwAAADZfcj2zaGhlYQAAA0QAAAAYAAAAJAC5AHhobXR4AAADXAAAABAAAABMAZAAAGxvY2EAAANsAAAAFAAAACgCEgIybWF4cAAAA4AAAAAeAAAAIAEfABJuYW1lAAADoAAAASIAAAIK9SUU/XBvc3QAAATEAAAAZgAAAJCTcMc2eJxVjbEOgjAURU+hFRBK1dGRL+ALnAiToyMLEzFpnPz/eAshwSa97517c/MwwJmeB9kwPl+0cf5+uGPZXsqPu4nvZabcSZldZ6kfyWnomFY/eScKqZNWupKJO6kXN3K9uCVoL7iInPr1X5baXs3tjuMqCtzEuagm/AAlzQgPAAB4nGNgYRBlnMDAysDAYM/gBiT5oLQBAwuDJAMDEwMrMwNWEJDmmsJwgCFeXZghBcjlZMgFCzOiKOIFAB71Bb8AeJy1kjFuwkAQRZ+DwRAwBtNQRUGKQ8OdKCAWUhAgKLhIuAsVSpWz5Bbkj3dEgYiUIszqWdpZe+Z7/wB1oCYmIoboiwiLT2WjKl/jscrHfGg/pKdMkyklC5Zs2LEfHYpjcRoPzme9MWWmk3dWbK9ObkWkikOetJ554fWyoEsmdSlt+uR0pCJR34b6t/TVg1SY3sYvdf8vuiKrpyaDXDISiegp17p7579Gp3p++y7HPAiY9pmTibljrr85qSidtlg4+l25GLCaS8e6rRxNBmsnERunKbaOObRz7N72ju5vdAjYpBXHgJylOAVsMseDAPEP8LYoUHicY2BiAAEfhiAGJgZWBgZ7RnFRdnVJELCQlBSRlATJMoLV2DK4glSYs6ubq5vbKrJLSbGrgEmovDuDJVhe3VzcXFwNLCOILB/C4IuQ1xTn5FPilBTj5FPmBAB4WwoqAHicY2BkYGAA4sk1sR/j+W2+MnAzpDBgAyEMQUCSg4EJxAEAwUgFHgB4nGNgZGBgSGFggJMhDIwMqEAYAByHATJ4nGNgAIIUNEwmAABl3AGReJxjYAACIQYlBiMGJ3wQAEcQBEV4nGNgZGBgEGZgY2BiAAEQyQWEDAz/wXwGAAsPATIAAHicXdBNSsNAHAXwl35iA0UQXYnMShfS9GPZA7T7LgIu03SSpkwzYTIt1BN4Ak/gKTyAeCxfw39jZkjymzcvAwmAW/wgwHUEGDb36+jQQ3GXGot79L24jxCP4gHzF/EIr4jEIe7wxhOC3g2TMYy4Q7+Lu/SHuEd/ivt4wJd4wPxbPEKMX3GI5+DJFGaSn4qNzk8mcbKSR6xdXdhSzaOZJGtdapd4vVPbi6rP+cL7TGXOHtXKll4bY1Xl7EGnPtp7Xy2n00zyKLVHfkHBa4IcJ2oD3cgggWvt/V/FbDrUlEUJhTn/0azVWbNTNr0Ens8de1tceK9xZmfB1CPjOmPH4kitmvOubcNpmVTN3oFJyjzCvnmrwhJTzqzVj9jiSX911FjeAAB4nG3HMRKCMBBA0f0giiKi4DU8k0V2GWbIZDOh4PoWWvq6J5V8If9NVNQcaDhyouXMhY4rPTcG7jwYmXhKq8Wz+p762aNaeYXom2n3m2dLTVgsrCgFJ7OTmIkYbwIbC6vIB7WmFfAAAA==") format("woff");
    }
    `,
      }}
    />
  );
};

export { ConfigurationGUI };
