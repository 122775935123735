import {
  forwardRef,
  ReactNode,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Html,
  Icosahedron,
  OrbitControls,
  PerspectiveCamera,
  Stats,
  useContextBridge,
} from "@react-three/drei";
import RealityModel from "./RealityModel";
import { ErrorBoundary } from "react-error-boundary";
import { ARCanvas } from "./ARCanvas";
import { ARPlacement } from "./ARPlacement";
import { Stage } from "./Stage";
import { EffectComposer, SMAA } from "@react-three/postprocessing";
import { PresetsType } from "@react-three/drei/helpers/environment-assets";
import { Camera, Vector3Tuple } from "three";
import { BakeShadows } from "./BakeShadows";
import { SceneContext } from "../context/SceneContext";
import { ConfigurationSchema } from "../configurator/models/ConfigurationSchema";
import { EventContext, EventProvider } from "../context/EventContext";
import { RealityViewerProps } from "./RealityViewer";
import { ConfigurationContext } from "../configurator/context/ConfigurationContext";
import { TextAnnotation } from "../annotations/components/TextAnnotation";
import { useScene } from "../context/SceneContext";
import { CameraControls } from "./CameraControls";
import { useSnapshot } from "valtio";

function DefaultModel() {
  return (
    <Icosahedron>
      <meshPhysicalMaterial name="BoxMaterial" color="#3D617C" />
    </Icosahedron>
  );
}

const Scene = forwardRef<
  HTMLCanvasElement,
  RealityViewerProps & { children?: ReactNode }
>(
  (
    {
      id = "reality-viewer",
      src,
      onLoaded,
      onError,
      autoRotate = false,
      autoRotateSpeed,
      polarAngle = 1.2,
      minPolarAngle,
      maxPolarAngle,
      azimuthAngle = -0.3,
      minAzimuthAngle,
      maxAzimuthAngle,
      distance,
      minDistance,
      maxDistance,
      cameraControls = true,
      arButton = true,
      arButtonExternalLink,
      gui = false,
      fov = 45,
      background = false,
      environment = "city",
      environmentIntensity = 0.7,
      contactShadow = true,
      contactShadowOpacity = 0.5,
      lightIntensity = 1,
      preserveDrawingBuffer = false,
      backgroundColor = "rgba(0,0,0,0)",
      initialCameraPosition = [-0.5, 0.6, 0.9],
      shadows = false,
      shadowBias = -0.0001,
      stats = false,
      onModelLoaded,
      onStartedLoading,
      configurationSchema,
      children,
      annotations = [],
      encrypted = true,
    },
    ref
  ) => {
    const camera = useRef<Camera>();

    const { state } = useScene();
    const { staged, shouldLoad } = useSnapshot(state);

    const ContextBridge = useContextBridge(
      EventContext,
      SceneContext,
      ConfigurationContext
    );

    const { settings } = useScene();
    useEffect(() => {
      settings.environmentIntensity = environmentIntensity;
    }, [environmentIntensity]);

    useEffect(() => {
      if (staged) {
        if (onLoaded) {
          onLoaded();
        }
      }
    }, [staged, onLoaded]);

    const cameraControlsRef = useRef(null!);

    return (
      <ARCanvas
        frameloop="demand"
        ref={ref}
        shadows
        sessionInit={{ requiredFeatures: ["hit-test"] }}
        gl={{ preserveDrawingBuffer }}
      >
        <ContextBridge>
          {stats && <Stats />}
          <PerspectiveCamera makeDefault ref={camera} fov={fov} />
          <CameraControls
            ref={cameraControlsRef}
            enableZoom={cameraControls}
            enableRotate={cameraControls}
            polarAngle={polarAngle}
            minPolarAngle={minPolarAngle}
            maxPolarAngle={maxPolarAngle}
            azimuthAngle={azimuthAngle}
            minAzimuthAngle={minAzimuthAngle}
            maxAzimuthAngle={maxAzimuthAngle}
            distance={distance}
            minDistance={minDistance}
            maxDistance={maxDistance}
            enablePan={false}
            autoRotate={staged && autoRotate}
            autoRotateSpeed={autoRotateSpeed}
            makeDefault
          />
          {shouldLoad && (
            <Suspense fallback={null}>
              <ARPlacement>
                <Stage
                  adjustCamera={true}
                  environment={environment}
                  intensity={lightIntensity}
                  shadows={shadows}
                  shadowBias={shadowBias}
                  fitObject={distance == null}
                  contactShadow={
                    !contactShadow
                      ? false
                      : {
                          blur: 2,
                          opacity: contactShadowOpacity,
                          position: [0, 0, 0],
                        }
                  }
                  background={background}
                >
                  <ErrorBoundary
                    onError={onError}
                    FallbackComponent={DefaultModel}
                  >
                    <RealityModel
                      onModelLoaded={() => {}}
                      onStartedLoading={onStartedLoading}
                      modelURL={src}
                      encrypted={encrypted}
                    />
                    <BakeShadows />
                  </ErrorBoundary>
                </Stage>
              </ARPlacement>
              {annotations.map((annotation, index) => {
                return <TextAnnotation key={index} {...annotation} />;
              })}
            </Suspense>
          )}
          {children}
        </ContextBridge>
      </ARCanvas>
    );
  }
);

export { Scene };
