import { useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Box3, Vector3, Vector3Tuple, Sphere, Mesh, Object3D } from "three";
import {
  ConfigurationContextType,
  useConfiguration,
} from "../configurator/context/ConfigurationContext";
import { useEvents } from "../context/EventContext";
import { USDZExporter } from "../utils/USDZExporter";
import { useScene, SceneContextType } from "../context/SceneContext";
import { useScreenshot } from "../hooks/useScreenshot";
import { ClickIntersection } from "./RealityModel";
import CameraControlsImpl from "camera-controls";

// component to expose api methods

export type RealityAPI = {
  getCameraPosition: () => Vector3Tuple;
  controls: {
    getAzimuthAngle: () => number;
    getPolarAngle: () => number;
    getDistance: () => number;
    setPosition: (
      x: number,
      y: number,
      z: number,
      transition?: boolean
    ) => void;
    setTarget: (x: number, y: number, z: number, transition?: boolean) => void;
    rotate: (azimuth: number, polar: number, transition?: boolean) => void;
    rotateTo: (azimuth: number, polar: number, transition?: boolean) => void;
    setDistance: (distance: number, transition?: boolean) => void;
    normalizeRotations: () => void;
    reset: () => void;
  };
  getScreenshot: () => Promise<Blob>;
  load: () => void;
} & ConfigurationContextType &
  SceneContextType;

type RealityAPIProviderProps = {
  onClickIntersection?: (clickIntersection: ClickIntersection) => void;
};

export const RealityAPIProvider = forwardRef<
  RealityAPI,
  RealityAPIProviderProps
>(({ onClickIntersection }, ref) => {
  const scene = useThree((state) => state.scene);
  const camera = useThree((state) => state.camera);
  // @ts-expect-error new in @react-three/fiber@7.0.5
  const controls = useThree((state) => state.controls) as CameraControlsImpl;
  const ee = useEvents();
  const configurator = useConfiguration();
  const sceneContext = useScene();
  const { getScreenshot } = useScreenshot();

  useImperativeHandle(
    ref,
    () => {
      return {
        getCameraPosition: () => camera?.position.toArray(),
        controls: {
          getAzimuthAngle: () => controls?.azimuthAngle,
          getPolarAngle: () => controls?.polarAngle,
          getDistance: () => controls?.distance,
          setPosition: (x, y, z, transition) =>
            controls?.setPosition(x, y, z, transition),
          setTarget: (x, y, z, transition) =>
            controls?.setTarget(x, y, z, transition),
          rotate: (a, p, transition) => controls?.rotate(a, p, transition),
          rotateTo: (a, p, transition) => controls?.rotateTo(a, p, transition),
          setDistance: (distance, transition) => controls.dollyTo(distance, transition),
          normalizeRotations: () => {
            controls?.normalizeRotations()
          },
          reset: () => {
            controls?.reset(true);
          }
        },
        getScreenshot: getScreenshot,
        load: () => {
          sceneContext.state.shouldLoad = true
        },
        ...configurator,
        ...sceneContext,
      };
    },
    [camera, controls]
  );

  const openUSDZ = async () => {
    if (scene) {
      const exporter = new USDZExporter();
      const arraybuffer = await exporter.parse(scene);
      const blob = new Blob([arraybuffer], {
        type: "application/octet-stream",
      });

      const anchor = document.createElement("a");
      anchor.href = URL.createObjectURL(blob);
      anchor.download = "model.usdz";
      anchor.rel = "ar";
      anchor.append(document.createElement("img"));
      anchor.click();
    }
  };

  useEffect(() => {
    ee.on("openUSDZ", () => openUSDZ());
    return () => {
      ee.removeAllListeners("openUSDZ");
    };
  }, []);

  useEffect(() => {
    ee.on("clickIntersection", (clickIntersection) =>
      onClickIntersection?.(clickIntersection)
    );
    return () => {
      ee.removeAllListeners("clickIntersection");
    };
  }, [onClickIntersection]);

  return null;
});
