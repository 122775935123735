import { Color, Material, MeshPhysicalMaterial, MeshStandardMaterial } from "three";
import { useScene } from "../context/SceneContext";
import { useSnapshot } from "valtio"
import { useTexture } from "@react-three/drei";

export function RealityTextureMaterial({ material, mapURL }: {material: MeshStandardMaterial | MeshPhysicalMaterial, mapURL: string}) {  
  const { graph, settings } = useScene();
  const materialsState = useSnapshot(graph.materials);
  const { environmentIntensity } = useSnapshot(settings)

  const props = materialsState[material.name];
  
  const map = useTexture(mapURL)

  const mergedProps = {
    ...material,
    ...props,
    map: map,
    color: !!props?.color ? (new Color(props.color)).convertSRGBToLinear() : material.color,
    envMapIntensity: environmentIntensity
  }
  if (material instanceof MeshPhysicalMaterial) {
    return <meshPhysicalMaterial {...mergedProps} />;
  } else {
    return <meshStandardMaterial {...mergedProps} />;
  }
}
