import { Camera } from "@react-three/fiber";
import { OrthographicCamera, PerspectiveCamera } from "three";

export function isPerspectiveCamera(
  camera: Camera
): camera is PerspectiveCamera {
  return (camera as PerspectiveCamera).isPerspectiveCamera;
}

export function isOrthographicCamera(
  camera: Camera
): camera is OrthographicCamera {
  return (camera as OrthographicCamera).isOrthographicCamera;
}
