import { useConfiguration } from "core";
import { useMemo } from "react";
import { useSnapshot } from "valtio";

// Create AR Link from project ID
export function useARLink(projectId: string) {
  // get configuration
  const { configuration } = useConfiguration();
  const configurationSnap = useSnapshot(configuration)

  // add configuration to url query
  const configuratorUrlQuery = useMemo(() => {
    return Object.keys(configurationSnap.parameters)
      .map((parameter) => {
        return `${parameter}:${encodeURIComponent(configurationSnap.parameters[parameter])}`;
      })
      .join(";");
  }, [configurationSnap]);

  const base = "https://viewer.reality.link";
  const queryParams = {
    project: projectId,
    configuration: configuratorUrlQuery,
    action: "ar",
  };
  return `${base}/?${Object.keys(queryParams)
    .map((param) => {
      return `${param}=${queryParams[param]}`;
    })
    .join("&")}`;
}
