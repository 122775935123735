import { Canvas } from "@react-three/fiber";
import { InteractionManager, XR, XRCanvasProps } from "@react-three/xr";
import { Props as ContainerProps } from "@react-three/fiber/dist/declarations/src/web/Canvas";
import { XRSession } from "three";
import { forwardRef, Suspense } from "react";
import { useEvents } from "../context/EventContext";
import EventEmitter from "eventemitter3";

// a canvas with AR functionalities
const ARCanvas = forwardRef<HTMLCanvasElement, XRCanvasProps>(({
  foveation,
  children,
  onCreated,
  sessionInit,
  ...rest
}, ref) => {

  const ee = useEvents();

  return (
    <Canvas
      vr
      onCreated={(state) => {
        onCreated?.(state);
        connectAPI(state.gl, sessionInit, ee);
      }}
      ref={ref}
      {...rest}
    >
      <XR foveation={foveation}>
        <Suspense fallback={null}>
          <InteractionManager>{children}</InteractionManager>
        </Suspense>
      </XR>
    </Canvas>
  );
})

let currentSession: XRSession | null = null;
// connect api and attach event to ee
function connectAPI(renderer, sessionInit, ee: EventEmitter) {
  ee.on("enter-webxr", () => {
    async function onSessionStarted(session) {
      session.addEventListener("end", onSessionEnded);

      renderer.xr.setReferenceSpaceType("local");

      await renderer.xr.setSession(session);

      if (sessionInit.domOverlay != null) {
        sessionInit.domOverlay.root.style.display = "";
      }

      currentSession = session;
    }

    function onSessionEnded(/*event*/) {
      currentSession?.removeEventListener("end", onSessionEnded);

      if (sessionInit.domOverlay != null) {
        sessionInit.domOverlay.root.style.display = "none";
      }

      currentSession = null;
    }
    if (currentSession === null) {
      // @ts-ignore
      navigator.xr
        .requestSession("immersive-ar", sessionInit)
        .then(onSessionStarted)
        .catch((error) => {});
    } else {
      currentSession.end();
    }
  });
}

export { ARCanvas };