/* eslint-disable */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { OrthographicCamera, PerspectiveCamera } from "three";
import {
  MOUSE,
  Vector2,
  Vector3,
  Vector4,
  Quaternion,
  Matrix4,
  Spherical,
  Box3,
  Sphere,
  Raycaster,
  MathUtils,
} from "three";
import {
  useFrame,
  useThree,
  EventManager,
  ReactThreeFiber,
} from "@react-three/fiber";
import CameraControlsDefault from "camera-controls";
import {
  isOrthographicCamera,
  isPerspectiveCamera,
} from "../utils/cameraUtils";

enum ACTION {
  NONE,
  ROTATE,
  TRUCK,
  OFFSET,
  DOLLY,
  ZOOM,
  TOUCH_ROTATE,
  TOUCH_TRUCK,
  TOUCH_OFFSET,
  TOUCH_DOLLY,
  TOUCH_ZOOM,
  TOUCH_DOLLY_TRUCK,
  TOUCH_DOLLY_OFFSET,
  TOUCH_ZOOM_TRUCK,
  TOUCH_ZOOM_OFFSET,
}

const subsetOfTHREE = {
  MOUSE: MOUSE,
  Vector2: Vector2,
  Vector3: Vector3,
  Vector4: Vector4,
  Quaternion: Quaternion,
  Matrix4: Matrix4,
  Spherical: Spherical,
  Box3: Box3,
  Sphere: Sphere,
  Raycaster: Raycaster,
  MathUtils: {
    DEG2RAD: MathUtils.DEG2RAD,
    clamp: MathUtils.clamp,
  },
};

CameraControlsDefault.install({ THREE: subsetOfTHREE });

export type CameraControlsProps = Omit<
  ReactThreeFiber.Overwrite<
    ReactThreeFiber.Object3DNode<
      CameraControlsDefault,
      typeof CameraControlsDefault
    >,
    {
      camera?: PerspectiveCamera | OrthographicCamera;
      domElement?: HTMLElement;
      enableDamping?: boolean;
      enableZoom?: boolean;
      enableRotate?: boolean;
      enablePan?: boolean;
      autoRotate?: boolean;
      autoRotateSpeed?: number;
      makeDefault?: boolean;
      regress?: boolean;
    }
  >,
  "ref"
>;

export const CameraControls = forwardRef<
  CameraControlsDefault,
  CameraControlsProps
>(
  (
    {
      camera,
      domElement,
      makeDefault,
      regress,
      enablePan,
      enableRotate,
      enableZoom,
      autoRotate = false,
      autoRotateSpeed = 10,
      ...restProps
    },
    ref
  ) => {
    const invalidate = useThree(({ invalidate }) => invalidate);
    const defaultCamera = useThree(({ camera }) => camera);
    const gl = useThree(({ gl }) => gl);
    const events = useThree(
      ({ events }) => events
    ) as EventManager<HTMLElement>;
    const set = useThree(({ set }) => set);
    const get = useThree(({ get }) => get);
    const performance = useThree(({ performance }) => performance);
    const explCamera = camera || defaultCamera;
    const explDomElement =
      domElement ||
      (typeof events.connected !== "boolean"
        ? events.connected
        : gl.domElement);
    const controls = useMemo(
      () => new CameraControlsDefault(explCamera, explDomElement),
      [explCamera, explDomElement]
    );

    const mouseButtons = useMemo(
      () => ({
        left: enableRotate ? ACTION.ROTATE : ACTION.NONE,
        middle: enableZoom ? ACTION.DOLLY : ACTION.NONE,
        right: enablePan ? ACTION.TRUCK : ACTION.NONE,
        wheel: enableZoom
          ? isPerspectiveCamera(explCamera)
            ? ACTION.DOLLY
            : isOrthographicCamera(explCamera)
            ? ACTION.ZOOM
            : ACTION.NONE
          : ACTION.NONE,
        shiftLeft: ACTION.NONE,
      }),
      [enablePan, enableRotate, enableZoom]
    );

    const touches = useMemo(
      () => ({
        one: enableRotate ? ACTION.TOUCH_ROTATE : ACTION.NONE,
        two: enableZoom
          ? isPerspectiveCamera(explCamera)
            ? ACTION.TOUCH_DOLLY
            : isOrthographicCamera(explCamera)
            ? ACTION.TOUCH_ZOOM
            : ACTION.NONE
          : ACTION.NONE,
        three: enablePan ? ACTION.TOUCH_TRUCK : ACTION.NONE,
      }),
      [enablePan, enableRotate, enableZoom]
    );

    const disableAutoRotate = useRef(!autoRotate);
    const userDragging = useRef(false);

    const autoRotationDirection = useRef(1);

    useFrame((_, delta) => {
      if (controls.enabled) controls.update(delta);

      if (!disableAutoRotate.current && autoRotate) {
        const nextValue =
          controls.azimuthAngle +
          autoRotationDirection.current *
            autoRotateSpeed *
            delta *
            MathUtils.DEG2RAD;
        if (
          nextValue > controls.maxAzimuthAngle ||
          nextValue < controls.minAzimuthAngle
        ) {
          autoRotationDirection.current = autoRotationDirection.current * -1;
        } else {
          controls.azimuthAngle +=
            autoRotationDirection.current *
            autoRotateSpeed *
            delta *
            MathUtils.DEG2RAD;
        }
      }
    });

    useEffect(() => {
      const callback = (e: any) => {
        invalidate();
        if (regress) performance.regress();
      };

      controls.addEventListener("update", callback);

      return () => {
        controls.removeEventListener("update", callback);
        controls.dispose();
      };
    }, [regress, controls, invalidate]);

    useEffect(() => {
      if (makeDefault) {
        const old = get().controls;
        // @ts-expect-error new in @react-three/fiber@7.0.5
        set({ controls: controls });
        return () => set({ controls: old });
      }
    }, [makeDefault, controls]);

    useEffect(() => {
      const onControlStart = () => {
        userDragging.current = true;
        disableAutoRotate.current = true;
      };
      const onControlEnd = () => {
        userDragging.current = false;
        if (!controls.active) {
          onRest();
        }
      };
      let timeout: number | undefined = undefined;
      const onRest = () => {
        if (!userDragging.current) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            userDragging.current = false;
            disableAutoRotate.current = false;
          }, 1000);
        }
      };
      const onTransitionStart = () => {
        disableAutoRotate.current = true;
      };

      if (enableRotate || enablePan || enableZoom) {
        controls.addEventListener("controlstart", onControlStart);
        controls.addEventListener("controlend", onControlEnd);
      }
      controls.addEventListener("rest", onRest);
      controls.addEventListener("transitionstart", onTransitionStart);
      return () => {
        controls.removeEventListener("controlstart", onControlStart);
        controls.removeEventListener("controlend", onControlEnd);
        controls.removeEventListener("transitionstart", onTransitionStart);
        controls.removeEventListener("rest", onRest);
        clearTimeout(timeout);
      };
    }, [autoRotate, controls, enablePan, enablePan, enableZoom]);

    return (
      <primitive
        ref={ref}
        object={controls}
        {...restProps}
        mouseButtons={mouseButtons}
        touches={touches}
      />
    );
  }
);
