export function Cross(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="m1.61.21.1.08L7 5.6l5.3-5.3a1 1 0 0 1 1.49 1.32l-.08.1L8.4 7l5.3 5.3a1 1 0 0 1-1.32 1.49l-.1-.08L7 8.4l-5.3 5.3a1 1 0 0 1-1.49-1.32l.08-.1L5.6 7 .29 1.7A1 1 0 0 1 1.61.22Z"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </svg>
  );
}
