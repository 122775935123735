import { SceneContextType } from "../../context/SceneContext";
import { Vector3Tuple } from "three";
import { parseBooleanValue, parseNumberValue, parseStringValue, parseVector3Tuple } from "./parser";

export interface HandlersType {
    position: HandlerType<Vector3Tuple, number>
    rotation: HandlerType<Vector3Tuple, number>
    scale: HandlerType<Vector3Tuple, number>
    visible: HandlerType<boolean, any>,
    color: HandlerType<string, any>,
    mapURL: HandlerType<string, any>,
    morphTarget: HandlerType<number, number>,
    [parameters: string]: HandlerType<any, any>
}

// every configuration parameter needs a handler and a parser
export interface HandlerType<ValueType, NestedValueType> {
    // what api methods need to be called for this configuration
    handler: (api: SceneContextType) => (target: string, value: ValueType) => void,
    // parse the string input to the needed value type
    parser: (value: string, input: number | string | boolean) => ValueType | null
    // the handler for a nested type. e.g. position -> position.x
    nestedHandler?: (nestedProperty: string) => (api: SceneContextType) => (target: string, value: NestedValueType) => void,
    nestedParser?: (value: string, input: number | string | boolean) => NestedValueType | null
}

export const handlers: HandlersType = {
  position: {
    handler: (api: SceneContextType) => api.setPosition,
    parser: parseVector3Tuple,
    nestedHandler: (nestedProperty: string) => (api: SceneContextType) => {
        return (target: string, value: number) => {
            const position = {};
            position[nestedProperty] = value;
            api.updatePosition(target, position)
        }
    },
    nestedParser: parseNumberValue
  },
  rotation: {
    handler: (api: SceneContextType) => api.setRotation,
    parser: parseVector3Tuple,
    nestedHandler: (nestedProperty: string) => (api: SceneContextType) => {
        return (target: string, value: number) => {
            const rotation = {};
            rotation[nestedProperty] = value;
            api.updateRotation(target, rotation)
        }
    },
    nestedParser: parseNumberValue
  },
  scale: {
    handler: (api: SceneContextType) => api.setScale,
    parser: parseVector3Tuple,
    nestedHandler: (nestedProperty: string) => (api: SceneContextType) => {
        return (target: string, value: number) => {
            const scale = {};
            scale[nestedProperty] = value;
            api.updatePosition(target, scale)
        }
    },
    nestedParser: parseNumberValue
  },
  visible: {
    handler: (api: SceneContextType) => api.setVisible,
    parser: parseBooleanValue,
  },
  color: {
    handler: (api: SceneContextType) => api.setColor,
    parser: parseStringValue,
  },
  mapURL: {
    handler: (api: SceneContextType) => api.setMapURL,
    parser: parseStringValue,
  },
  morphTarget: {
    handler: (api: SceneContextType) => (name: string, value: number) => {
        console.warn(`You need to specify a morphTarget like "morphTarget.myMorphTarget".`);  
    },
    nestedHandler: (nestedProperty: string) => (api: SceneContextType) => {
        return (target: string, value: number) => {
            api.setMorphTargetInfluence(target, nestedProperty, value)
        }
    },
    parser: parseNumberValue,
    nestedParser: parseNumberValue,
  }
};