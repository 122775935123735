import { useFrame, useThree } from "@react-three/fiber";
import { PresetsType } from "@react-three/drei/helpers/environment-assets";
import { Environment } from "./Environment";
import {
  Box3,
  Box3Helper,
  BoxHelper,
  Group,
  PerspectiveCamera,
  Spherical,
  Vector3,
  Sphere,
} from "three";
import { reduceVertices } from "../utils/reduceVertices";
import {
  MutableRefObject,
  Suspense,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useScene } from "../context/SceneContext";
import { useSnapshot } from "valtio";
import CameraControlsImpl from "camera-controls";
import { ContactShadows } from "./ContactShadow";

type Props = JSX.IntrinsicElements["group"] & {
  shadows?: boolean;
  adjustCamera?: boolean;
  introAnimation?: boolean;
  environment?: PresetsType;
  background?: boolean;
  intensity?: number;
  ambience?: number;
  fitObject?: boolean;
  shadowBias?: number;
  contactShadow?:
    | {
        blur: number;
        opacity?: number;
        position?: [x: number, y: number, z: number];
      }
    | false;
};

export function Stage({
  children,
  shadows = true,
  adjustCamera = true,
  introAnimation = true,
  environment = "city",
  background = false,
  intensity = 1,
  shadowBias = 0,
  fitObject = true,
  contactShadow = {
    blur: 2,
    opacity: 0.5,
    position: [0, 0, 0],
  },
  ...props
}: Props) {
  const camera = useThree((state) => state.camera);
  // @ts-expect-error new in @react-three/fiber@7.0.5
  const defaultControls = useThree(
    (state) => state.controls
  ) as CameraControlsImpl;
  const outer = useRef<Group>(null!);
  const inner = useRef<Group>(null!);
  const [{ radius, width, height }, set] = useState({
    radius: 1,
    width: 1,
    height: 1,
  });

  const { graph, state } = useScene();
  const { modelLoaded, configurationLoaded, staged } = useSnapshot(state);
  const objectsSnapshot = useSnapshot(graph.objects);

  const boundingBox = useRef(new Box3());
  const boundingSphere = useRef(new Sphere());

  const updateTarget = () => {
    outer.current.position.set(0, 0, 0);
    outer.current.updateWorldMatrix(true, true);

    const bound = (box: Box3, vertex: Vector3): Box3 => {
      return box.expandByPoint(vertex);
    };
    boundingBox.current = reduceVertices(inner.current, bound, new Box3());

    const center = new Vector3();
    const height = boundingBox.current.max.y - boundingBox.current.min.y;
    const width = boundingBox.current.max.x - boundingBox.current.min.x;
    boundingBox.current.getCenter(center);
    boundingBox.current.getBoundingSphere(boundingSphere.current);
    boundingSphere.current.center.set(0, height / 2, 0);
    set({ radius: boundingSphere.current.radius, width, height });

    outer.current.position.set(-center.x, -center.y + height / 2, -center.z);
  };

  useEffect(() => {
    updateTarget();
  }, [children, objectsSnapshot]);

  const target = useRef(new Vector3());

  useLayoutEffect(() => {
    const shouldTransition = staged;
    if (adjustCamera) {
      let orbitRadius = radius * 2;
      if (camera instanceof PerspectiveCamera) {
        orbitRadius = (radius * 120) / camera.fov;
      }

      const far = 2 * Math.max(radius, orbitRadius * 8);
      const near = far / 1000;
      camera.near = near;
      camera.far = far;

      const ctrl = defaultControls;
      if (ctrl) {
        ctrl.minDistance = orbitRadius * 0.4;
        ctrl.maxDistance = orbitRadius * 8;
        if (fitObject) {
          ctrl.distance = 1;
          ctrl.fitToSphere(boundingSphere.current, shouldTransition);
        } else {
          ctrl.setTarget(0, height / 2, 0);
        }
      }
    }
    if (modelLoaded && configurationLoaded) {
      setTimeout(() => {
        state.staged = true;
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultControls,
    radius,
    height,
    width,
    adjustCamera,
    fitObject,
    configurationLoaded,
  ]);

  useEffect(() => {
    if (staged) {
      if (introAnimation) {
        const ctrl = defaultControls;
        if (ctrl) {
          // @ts-ignore
          const targetAzimuthAngle = ctrl._sphericalEnd.theta;
          // @ts-ignore
          const targetPolarAngle = ctrl._sphericalEnd.phi;
          // @ts-ignore
          const targetDistance = ctrl._sphericalEnd.radius;
          ctrl.azimuthAngle = targetAzimuthAngle - Math.PI;
          ctrl.polarAngle = targetPolarAngle - Math.PI / 4;
          ctrl.distance = targetDistance * 2;
          setTimeout(() => {
            ctrl.rotateTo(targetAzimuthAngle, targetPolarAngle, true);
            ctrl.dollyTo(targetDistance, true);
          }, 100);
        }
      }
      inner.current.visible = true;
    }
  }, [staged, introAnimation]);

  return (
    <group {...props}>
      <group ref={outer}>
        <group ref={inner} visible={false}>
          {children}
        </group>
      </group>
      {contactShadow && (
        <ContactShadows
          rotation={[Math.PI / 2, 0, 0]}
          width={radius * 2}
          height={radius * 2}
          far={radius / 2}
          {...contactShadow}
        />
      )}
      {environment && (
        <Environment background={background} preset={environment} />
      )}
      <ambientLight intensity={intensity * 0.4} />
      <spotLight
        penumbra={1}
        position={[2 * radius, 3 * radius, 1.5 * radius]}
        distance={radius * 10}
        intensity={intensity * 2.2}
        castShadow={shadows}
        shadow-bias={shadowBias}
        shadow-mapSize-width={4096}
        shadow-mapSize-height={4096}
      />
      <directionalLight
        position={[-3 * radius, 1 * radius, -4 * radius]}
        intensity={intensity * 0.4}
      />
    </group>
  );
}
