import { createContext, ReactNode, useContext, useRef } from "react";
import { AnimationAction, AnimationActionLoopStyles, Color, Material, Mesh, MeshStandardMaterial, Object3D, Vector3Tuple } from "three";
import { proxy, useSnapshot } from "valtio";

export interface MaterialConfig {
  name?: string;
  color?: string;
  opacity?: number;
  roughness?: number;
  metalness?: number;
  mapURL?: string;
  // map?: string;
  // videoMap?: string;
  // alphaMap?: string;
  // alphaVideoMap?: string;
  // aoMap?: string;
  // aoVideoMap?: string;
  // aoMapIntensity?: number;
  // emissive?: string;
  // emissiveMap?: string;
  // emissiveVideoMap?: string;
  // emissiveIntensity?: number;
  // metalnessMap?: string;
  // metalnessVideoMap?: string;
  // roughnessMap?: string;
  // roughnessVideoMap?: string;
  // normalMap?: string;
  // normalVideoMap?: string;
}

export interface SceneGraph {
  objects: {
    [name: string]: {
      position?: Vector3Tuple;
      scale?: Vector3Tuple;
      rotation?: Vector3Tuple;
      visible?: boolean;
      morphTargets?: {
        [target: string]: number;
      };
    };
  };
  materials: {
    [name: string]: MaterialConfig;
  };
  animations: {
    names: string[],
    actions: {
      [action: string]: AnimationAction | null
    }
  }
}

export interface SceneContextType {
  state: {
    modelLoaded: boolean,
    configurationLoaded: boolean,
    staged: boolean,
    shouldLoad: boolean
  },
  graph: SceneGraph,
  settings: {
    environmentIntensity: number
  },
  addObject: (object: Object3D) => void;
  getObjectNames: () => string[];
  getMaterialNames: () => string[];
  updateMaterial: (name: string, config: MaterialConfig) => void;
  setScale: (name: string, scale: Vector3Tuple) => void;
  updateScale: (
    name: string,
    scale: {
      x?: number;
      y?: number;
      z?: number;
    }
  ) => void;
  setPosition: (name: string, position: Vector3Tuple) => void;
  updatePosition: (
    name: string,
    position: {
      x?: number;
      y?: number;
      z?: number;
    }
  ) => void;
  setRotation: (name: string, rotation: Vector3Tuple) => void;
  updateRotation: (
    name: string,
    rotation: {
      x?: number;
      y?: number;
      z?: number;
    }
  ) => void;
  setVisible: (name: string, visible: boolean) => void;
  setColor: (name: string, color: string) => void;
  setMapURL: (name: string, mapURL: string) => void;
  setMorphTargetInfluence: (
    name: string,
    morphTarget: string,
    influence: number
  ) => void;
  getAnimations: () => string[],
  playAnimation: (name: string) => void,
  stopAllAnimations: () => void
};

export const SceneContext = createContext<SceneContextType>(undefined!);

type SceneProviderProps = {
  autoLoad?: boolean,
  children: ReactNode
}

export const SceneProvider = ({ autoLoad, children }: SceneProviderProps) => {
  const state = useRef(proxy({ modelLoaded: false, configurationLoaded: false, staged: false, shouldLoad: autoLoad ?? false })).current
  const graph = useRef<SceneGraph>(proxy({ objects: {}, materials: {}, animations: { names: [], actions: {}} })).current
  const settings = useRef(proxy({ environmentIntensity: 0.7 })).current

  const addObject = (object: Object3D) => {
    if (!graph.objects[object.name]) {
      graph.objects[object.name] = {
        position: object.position.toArray(),
        scale: object.scale.toArray(),
        rotation: object.rotation.toVector3().toArray(),
        visible: object.visible,
        morphTargets: {},
      };      
      if (
        object instanceof Mesh &&
        object.morphTargetInfluences &&
        object.morphTargetDictionary
      ) {
        Object.keys(object.morphTargetDictionary).forEach((morphTarget) => {
          // @ts-ignore
          graph.objects[object.name].morphTargets[morphTarget] =
            // @ts-ignore
            object.morphTargetInfluences[
              // @ts-ignore
              object.morphTargetDictionary[morphTarget]
            ];
        });
      }
    }
    if (
      object instanceof Mesh &&
      object.material instanceof MeshStandardMaterial &&
      !graph.materials[object.material.name]
    ) {
      const materialConfig: MaterialConfig = {
        color:
          "#" +
          new Color(object.material.color).convertLinearToSRGB().getHexString(),
          roughness: object.material.roughness,
          metalness: object.material.metalness,
          opacity: object.material.opacity,
          name: object.material.name
      };
      graph.materials[object.material.name] = materialConfig;
    }
  };

  const getObjectNames = () => {
    return Object.keys(graph.objects);
  };

  const getMaterialNames = () => {
    return Object.keys(graph.materials);
  };
  const updateMaterial = (name: string, config: MaterialConfig) => {
    graph.materials[name] = {
      ...graph.materials[name],
      ...config,
    };
  };
  const setScale = (name: string, scale: Vector3Tuple) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    node.scale = scale;
  };

  const updateScale = (name: string, scale) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    const nodeScale = (node.scale = node.scale || [0, 0, 0]);
    if (scale.x) {
      nodeScale[0] = scale.x;
    }
    if (scale.y) {
      nodeScale[1] = scale.y;
    }
    if (scale.z) {
      nodeScale[2] = scale.z;
    }
  };
  const setPosition = (name: string, position: Vector3Tuple) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    node.position = position;
  };
  const updatePosition = (name: string, position) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    const nodePosition = (node.position = node.position || [0, 0, 0]);
    if (position.x) {
      nodePosition[0] = position.x;
    }
    if (position.y) {
      nodePosition[1] = position.y;
    }
    if (position.z) {
      nodePosition[2] = position.z;
    }
  };
  const setRotation = (name: string, rotation: Vector3Tuple) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    node.rotation = rotation;
  };
  const updateRotation = (name: string, rotation) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    const nodeRotation = (node.rotation = node.rotation || [0, 0, 0]);
    if (rotation.x) {
      nodeRotation[0] = rotation.x;
    }
    if (rotation.y) {
      nodeRotation[1] = rotation.y;
    }
    if (rotation.z) {
      nodeRotation[2] = rotation.z;
    }
  };
  const setVisible = (name: string, visible: boolean) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    node.visible = visible;
  };
  const setColor = (name: string, color: string) => {
    const material = (graph.materials[name] = graph.materials[name] || {});
    material.color = color;
  };
  const setMapURL = (name: string, mapURL: string) => {
    const material = (graph.materials[name] = graph.materials[name] || {});
    material.mapURL = mapURL;
  };
  const setMorphTargetInfluence = (
    name: string,
    morphTarget: string,
    influence: number
  ) => {
    const node = (graph.objects[name] = graph.objects[name] || {});
    const morphTargets = (node.morphTargets = node.morphTargets || {});
    morphTargets[morphTarget] = influence;
  };

  const getAnimations = () => {
    return graph.animations.names;
  }

  const playAnimation = (name: string) => {
    const action = graph.animations.actions[name];
    if (!action) {
      console.warn(`The animation "${name}" does not exist!`);
    } else {
      action.reset().play();
    }
  }

  const stopAllAnimations = () => {
    const action = graph.animations.actions[graph.animations.names[0]];
    if (action) {
      action.getMixer().stopAllAction()
    }
  }

  const exposed = {
    state,
    graph,
    settings,
    addObject,
    getObjectNames,
    getMaterialNames,
    updateMaterial,
    setScale,
    updateScale,
    setPosition,
    updatePosition,
    setRotation,
    updateRotation,
    setVisible,
    setColor,
    setMapURL,
    setMorphTargetInfluence,
    getAnimations,
    playAnimation,
    stopAllAnimations
  };  

  return (
    <SceneContext.Provider value={exposed}>{children}</SceneContext.Provider>
  );
};

export function useScene() {
  const context = useContext(SceneContext);
  if (!context) {
    throw new Error(
      'No SceneContext found when calling useScene.'
    );
  }
  return context;
};