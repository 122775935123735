import { ObjectMap } from "@react-three/fiber";
import { GLTFLoader, DRACOLoader, GLTF } from "three-stdlib";
import { MeshoptDecoder } from "three-stdlib/libs/MeshoptDecoder";
import { useAsset } from "use-asset";

function buildGraph(object: THREE.Object3D) {
  const data: ObjectMap = { nodes: {}, materials: {} };
  if (object) {
    object.traverse((obj: any) => {
      if (obj.name) {
        data.nodes[obj.name] = obj;
      }
      if (obj.material && !data.materials[obj.material.name]) {
        data.materials[obj.material.name] = obj.material;
      }
    });
  }
  return data;
}

let dracoLoader: DRACOLoader | null = null;

const loadingFn = async (data: ArrayBuffer, version: string) => {
  const loader = new GLTFLoader();
  if (!dracoLoader) {
    dracoLoader = new DRACOLoader();
  }
  dracoLoader.setDecoderPath(
    "https://www.gstatic.com/draco/versioned/decoders/1.4.3/"
  );
  loader.setDRACOLoader(dracoLoader);

  loader.setMeshoptDecoder(
    typeof MeshoptDecoder === "function" ? MeshoptDecoder() : MeshoptDecoder
  );

  return new Promise<GLTF>((res, reject) =>
    loader.parse(
      data,
      "./",
      (data: any) => {
        if (data.scene) Object.assign(data, buildGraph(data.scene));
        res(data);
      },
      (error) => reject(`Could not load: ${error.message}`)
    )
  );
};

export const useGLTFParser = (data: ArrayBuffer, version: string) => {
  return useAsset(loadingFn, data, version);
};
