import { SceneContextType } from "../../context/SceneContext";
import { applyCommand, ConfigurationCommand } from "./ConfigurationCommand";

// a configuration parameter needs a name, a type and a default value
export interface ConfigurationParameter {
  name: string;
  type: "select" | "number" | "toggle" | "text";
  default: string | number | boolean;
}

// select parameters need a list of options that need a name and one or more commands
export interface ConfigurationSelectParameter extends ConfigurationParameter {
  options: {
    name: string;
    commands: ConfigurationCommand[];
  }[];
}

// number parameters need a default value and one or more commands
// it is also possible to specify min, max and step values for the UI
export interface ConfigurationNumberParameter extends ConfigurationParameter {
  default: number;
  settings: {
    min: number;
    max: number;
    step: number;
  };
  commands: ConfigurationCommand[];
}

export interface ConfigurationTextParameter extends ConfigurationParameter {
  default: string;
  commands: ConfigurationCommand[];
}

// boolean parameters need options like the select parameter,but they are predefined (on and off)
export interface ConfigurationBooleanParameter extends ConfigurationParameter {
  default: boolean;
  options: {
    on: {
      name: string;
      commands: ConfigurationCommand[];
    };
    off: {
      name: string;
      commands: ConfigurationCommand[];
    };
  };
}

export function isConfigurationSelectParameter(
  parameter: ConfigurationParameter
): parameter is ConfigurationSelectParameter {
  return parameter.type === "select";
}
export function isConfigurationNumberParameter(
  parameter: ConfigurationParameter
): parameter is ConfigurationNumberParameter {
  return parameter.type === "number";
}
export function isConfigurationTextParameter(
  parameter: ConfigurationParameter
): parameter is ConfigurationTextParameter {
  return parameter.type === "text";
}
export function isConfigurationToggleParameter(
  parameter: ConfigurationParameter
): parameter is ConfigurationBooleanParameter {
  return parameter.type === "toggle";
}

// method to apply a parameter with the input value
export function applyParameter(
  parameter: ConfigurationParameter,
  input: boolean | number | string,
  api: SceneContextType
) {
  if (isConfigurationNumberParameter(parameter)) {
    for (const command of parameter.commands) {
      applyCommand(command, input, api);
    }
  } else if (
    isConfigurationSelectParameter(parameter) &&
    typeof input === "string"
  ) {
    const commands = parameter.options.find(p => {
      return p.name === input;
    })?.commands || [];
    for (const command of commands) {
      applyCommand(command, input, api);
    }
  } else if (
    isConfigurationToggleParameter(parameter) &&
    (typeof input === "boolean" || input === "on" || input === "off")
  ) {
    let key: "on" | "off";
    if (typeof input === "boolean") {
      key = input ? "on" : "off"
    } else {
      key = input
    }
    const commands = parameter.options[key].commands;
    for (const command of commands) {
      applyCommand(command, input, api);
    }
  } else if (
    isConfigurationTextParameter(parameter) &&
    typeof input === "string"
  ) {
    const commands = parameter.commands;
    for (const command of commands) {
      applyCommand(command, input, api);
    }
  }
}
