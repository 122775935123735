import { FunctionComponent, useEffect } from "react";
import { subscribeKey } from "valtio/utils";
import { isConfigurationSelectParameter, isConfigurationNumberParameter } from "../models/ConfigurationParameter";
import { useConfiguration } from "../context/ConfigurationContext";

type ConnectUIProps = {
  id?: string;
};

// connect html elements with the attribute "reality-parameter" to the configurator
const ConnectUI: FunctionComponent<ConnectUIProps> = ({ id }) => {
  const { configurationSchema, configuration, updateConfiguration } =
    useConfiguration();

  const isParameterAvailable = (parameter: string) => {
    return configurationSchema.parameters.filter(p => {
      return p.name === parameter
    }).length > 0
  };

  useEffect(() => {
    document.querySelectorAll("[reality-parameter]").forEach((el) => {
      const realityId = el.getAttribute("reality-id");
      if (realityId) {
        if (realityId != id) return;
      }
      const parameterName = el.getAttribute("reality-parameter");
      if (parameterName) {
        const parameter = configurationSchema.parameters.find(p => p.name === parameterName);
        if (parameter != null) {
          if (el instanceof HTMLInputElement) {
            if (el.type === "checkbox") {
              if (parameter.type === "toggle") {
                el.addEventListener("input", () => {
                  updateConfiguration(parameterName, el.checked);
                });
                subscribeKey(
                  configuration.parameters,
                  parameterName,
                  (value) => {
                    el.checked = value as boolean;
                  }
                );
                el.checked = configuration.parameters[parameterName] as boolean;
              } else {
                console.warn(
                  `An InputElement of type "checkbox" can only control a parameter of type "toggle". The parameter ${parameterName} is of type "${parameter.type}"`
                );
              }
            } else if (el.type === "number" || el.type === "range") {
              if (isConfigurationNumberParameter(parameter)) {
                if (parameter.settings.min) {
                  el.min = parameter.settings.min.toString();
                }
                if (parameter.settings.max) {
                  el.max = parameter.settings.max.toString();
                }
                if (parameter.settings.step) {
                  el.step = parameter.settings.step.toString();
                }
                el.addEventListener("input", () => {
                  updateConfiguration(parameterName, el.value);
                });
                subscribeKey(
                  configuration.parameters,
                  parameterName,
                  (value) => {
                    el.value = value as string;
                  }
                );
                el.value = configuration.parameters[parameterName] as string;
              } else {
                console.warn(
                  `An InputElement of type "number" can only control a parameter of type "number". The parameter ${parameterName} is of type "${parameter.type}"`
                );
              }
            }
          } else if (el instanceof HTMLSelectElement) {
            if (isConfigurationSelectParameter(parameter)) {
              // clear all options
              el.innerHTML = "";
              // add all available options
              for (const option of parameter.options) {
                const optionEl = document.createElement("option");
                optionEl.value = option.name;
                optionEl.innerText = option.name;
                el.add(optionEl);
              }
              el.addEventListener("change", () => {
                updateConfiguration(parameterName, el.value);
              });
              subscribeKey(configuration.parameters, parameterName, (value) => {
                el.value = value as string;
              });
              el.value = configuration.parameters[parameterName].toString();
            } else {
              console.warn(
                `An SelectElement can only control a parameter of type "select". The parameter ${parameterName} is of type "${parameter.type}"`
              );
            }
          }
          // if element is no input element it needs a value which will be applied on click
          else {
            const value = el.getAttribute("reality-value");
            if (value) {
              const setActiveState = () => {
                if (
                  configuration.parameters[parameterName].toString() === value
                ) {
                  el.setAttribute("reality-active", "");
                } else {
                  el.removeAttribute("reality-active");
                }
              };
              setActiveState();
              subscribeKey(
                configuration.parameters,
                parameterName,
                setActiveState
              );
              el.addEventListener("click", () => {
                updateConfiguration(parameterName, value);
              });
            } else {
              console.warn(
                `You need to add the attribute "reality-value" to your element.`
              );
            }
          }
        } else {
          console.warn(`The parameter "${parameterName}" is not available.`);
        }
      }
    });
  }, []);

  return null;
};

export { ConnectUI };
