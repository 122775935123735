export function Loading({ projectId, visible }) {
  return (
    <div
      className="not-found-overlay-wrapper"
      style={{
        background: "transparent",
        opacity: visible ? "100%" : "0%",
        transition: "opacity 200ms ease-in-out",
        pointerEvents: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 100,
          color: "white",
          left: "30px",
          top: "30px",
          fontSize: "1rem",
          background: "black",
          padding: "1em 2em",
          borderRadius: "999em",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
        <span>loading...</span>
      </div>
      <div
        style={{
          background: `url(https://quunqypazqosotdcxsjj.supabase.co/storage/v1/object/public/project-bucket/${projectId}/thumbnail.png)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          filter: "blur(4px)",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      ></div>
    </div>
  );
}
