import { RealityAPI, RealityViewer } from "core";
import { Overlay } from "./Overlay";
import { useProjectDetails } from "../hooks/useProjectDetails";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ARButton } from "./ARButton";
import { RealityViewerWrapperProps } from "./RealityViewerWrapper";
import { Loading } from "./Loading";
import { StartScreen } from "./StartScreen";

// load project with projectId
export const ProjectLoader = forwardRef<RealityAPI, RealityViewerWrapperProps>(({ projectId, id, ...rest }, ref) => {
  
  const realityAPI = useRef<RealityAPI>(null!);
  useImperativeHandle(ref, () => realityAPI.current);
  
  const { model, configuration, options, annotations } = useProjectDetails(projectId);
  const [error, setError] = useState(false);

  const [startedLoading, setStartedLoading] = useState(options.autoLoad ?? false);
  const [loading, setLoading] = useState(options.autoLoad ?? false);
  const [loaded, setLoaded] = useState(false);

  const onPlay = useCallback(() => {    
    if (realityAPI.current) {
      setLoading(true);
      setStartedLoading(true)
      realityAPI.current.load();
    }
  }, [realityAPI])

  return (
    <>
      {error && <Overlay message="Error while loading the project." />}
      {!model && <Overlay message="Project not found!" />}
      {model && (
        <RealityViewer
          id={id}
          configurationSchema={configuration}
          {...options}
          annotations={annotations}
          onError={(error) => {
            setError(true);
            rest.onError?.(error);
          }}
          {...rest}
          onStartedLoading={() => {
            setLoading(true);
            setStartedLoading(true);
          }}
          onLoaded={() => {
            setLoading(false);
            setLoaded(true);
          }}
          src={model}
          ref={realityAPI}
        >
          <Loading projectId={projectId} visible={loading && !error} />
          <StartScreen projectId={projectId} visible={!startedLoading && !error && !loading} onPlay={onPlay} />
          { loaded && <ARButton enabled projectId={projectId}/> }
        </RealityViewer>
      )}
    </>
  );
})