import { Html } from "@react-three/drei";
import { HtmlProps } from "@react-three/drei/web/Html";
import { useThree } from "@react-three/fiber";
import { FunctionComponent, useEffect, useState } from "react";
import { AnnotationMarker } from "./AnnotationMarker";

export type AnnotationProps = {
  markerLabel?: string;
  alwaysExpanded?: boolean
} & HtmlProps;

const BasicAnnotation: FunctionComponent<AnnotationProps> = ({
  children,
  markerLabel,
  alwaysExpanded = false,
  ...props
}) => {
  const [occluded, setOccluded] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const controls = useThree((state) => state.controls);

  useEffect(() => {
    if (controls) {
      controls.addEventListener("start", () => setExpanded(false));
    }
    return () => {
      if (controls) {
        controls.removeEventListener("start", () => setExpanded(false));
      }
    };
  }, [controls]);

  const onOcclude = (visible: boolean) => {
    setOccluded(visible);
    return null;
  };

  return (
    <Html
      as="div"
      center
      zIndexRange={[100, 0]}
      occlude
      onOcclude={onOcclude}
      style={{
        transition: "all 0.5s",
        opacity: occluded ? 0.2 : 1,
      }}
      {...props}
    >
      <AnnotationMarker label={markerLabel} onClick={() => setExpanded(!expanded)} />
      {(expanded || alwaysExpanded) && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "-10px",
            transform: "translateX(100%)",
          }}
        >
          {children}
        </div>
      )}
    </Html>
  );
};

export { BasicAnnotation };
