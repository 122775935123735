import { SceneContextType } from "../../context/SceneContext";
import { handlers } from "../utils/handler";

export type ObjectType = "light" | "camera" | "mesh" | "group" | "material";

export interface ConfigurationCommand {
  target: string;
  property: string;
  value: string;
}

// methods that applies a configuration command
export function applyCommand(command: ConfigurationCommand, input: string | number | boolean, api: SceneContextType) {

    // get property from command e.g. "postion.x" -> ["position", "x"], "rotation" -> ["rotation"]
    const [property, nestedProperty] = command.property.split(".");
    // get handler for property
    const handlerObject = handlers[property];

    if (!handlerObject) {
        console.warn(`The property ${property} is not supported!`);
        return;
    }
    let handler = handlerObject.handler(api);
    let parser = handlerObject.parser
    if (nestedProperty != null && handlerObject.nestedHandler) {
        handler = handlerObject.nestedHandler(nestedProperty)(api)
        if (handlerObject.nestedParser) {
            parser = handlerObject.nestedParser
        }
    }
    
    // parse value
    const parsedValue = parser(command.value, input)
    if (parsedValue == null) {
        console.warn(`Cannot parse "${command.value}"!`);
        return;
    }

    // apply command with parsed value
    handler(command.target, parsedValue);
}