import { createAsset, useAsset } from "use-asset";

const enc = new TextEncoder();

const getPasswordKey = (password: string) =>
  crypto.subtle.importKey("raw", enc.encode(password), "PBKDF2", false, [
    "deriveKey",
  ]);

const deriveKey = (
  passwordKey: CryptoKey,
  salt: Uint8Array,
  keyUsage: Iterable<KeyUsage>
) =>
  crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 100000,
      hash: "SHA-256",
    },
    passwordKey,
    { name: "AES-GCM", length: 256 },
    false,
    keyUsage
  );

export async function encryptData(data: ArrayBuffer, password: string) {
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const passwordKey = await getPasswordKey(password);
  const aesKey = await deriveKey(passwordKey, salt, ["encrypt"]);
  const encryptedContent = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    aesKey,
    data
  );

  const encryptedContentArr = new Uint8Array(encryptedContent);
  let buff = new Uint8Array(
    salt.byteLength + iv.byteLength + encryptedContentArr.byteLength
  );
  // save salt in encrypted data
  buff.set(salt, 0);
  // save iv in encrypted data
  buff.set(iv, salt.byteLength);
  buff.set(encryptedContentArr, salt.byteLength + iv.byteLength);
  return buff;
}

export async function decryptData(encryptedData: ArrayBuffer, password: string) {
  // read salt from encrypted data
  const salt = encryptedData.slice(0, 16);
  // read iv from encrypted data
  const iv = encryptedData.slice(16, 16 + 12);
  const data = encryptedData.slice(16 + 12);
  const passwordKey = await getPasswordKey(password);
  const aesKey = await deriveKey(passwordKey, new Uint8Array(salt), [
    "decrypt",
  ]);
  const decryptedContent = await crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    aesKey,
    data
  );
  return new Uint8Array(decryptedContent);
}

export function useEncrypt(data: ArrayBuffer, password: string) {
  return useAsset(encryptData, data, password);
}

export function useDecrypt(data: ArrayBuffer, password: string) {
  return useAsset(decryptData, data, password);
}
