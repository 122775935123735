import { useThree } from "@react-three/fiber";
import { useCallback, useEffect } from "react";
import { debounce } from "debounce";
import { useScene } from "../context/SceneContext";
import { useSnapshot } from "valtio";

// bake shadows in shadow map
export function BakeShadows() {
    const gl = useThree((state) => state.gl)
    const regress = useThree((state) => state.performance.regress)
    const { graph } = useScene();

    const graphSnapshot = useSnapshot(graph)

    useEffect(() => {
      gl.shadowMap.autoUpdate = false
      gl.shadowMap.needsUpdate = true
      return () => {
        gl.shadowMap.autoUpdate = gl.shadowMap.needsUpdate = true
      }
    }, [gl.shadowMap])
  
    const updateShadowMap = useCallback(debounce(() => {
        gl.shadowMap.needsUpdate = true
      }, 50), [])
  
    useEffect(() => {
      regress()
      updateShadowMap();
    }, [graphSnapshot])
  
    return null
  }