import { useLoader, useThree } from "@react-three/fiber";
import { useLayoutEffect } from "react";
import {
  FloatType,
  EquirectangularReflectionMapping,
  CubeTextureLoader,
  Texture,
  Scene,
  Loader,
} from "three";
import { RGBELoader } from "three-stdlib";

// environment component based on @react-three/drei
// custom implementation to access self hosted hdrs

export const presetsObj = {
  sunset: "venice_sunset_1k.hdr",
  dawn: "kiara_1_dawn_1k.hdr",
  night: "dikhololo_night_1k.hdr",
  warehouse: "empty_warehouse_01_1k.hdr",
  forest: "forest_slope_1k.hdr",
  apartment: "lebombo_1k.hdr",
  studio: "studio_small_03_1k.hdr",
  city: "potsdamer_platz_1k.hdr",
  park: "rooitou_park_1k.hdr",
  lobby: "st_fagans_interior_1k.hdr",
  metal: "metal.hdr",
  metal2: "metal2.hdr",
};

export type PresetsType = keyof typeof presetsObj;

const HDRI_ROOT = "https://viewer.reality.link/hdri/";

type Props = {
  background?: boolean;
  files?: string | string[];
  path?: string;
  preset?: PresetsType;
  scene?: Scene;
  extensions?: (loader: Loader) => void;
};

export function Environment({
  background = false,
  path = "",
  preset = "city",
  scene,
  extensions,
}: Props) {
  if (!(preset in presetsObj)) {
    throw new Error(
      "Preset must be one of: " + Object.keys(presetsObj).join(", ")
    );
  }
  const file = presetsObj[preset];
  path = HDRI_ROOT;
  const defaultScene = useThree(({ scene }) => scene);
  const loaderResult: Texture = useLoader(
    // @ts-expect-error
    RGBELoader,
    file,
    (loader) => {
      loader.setPath(path);
      // @ts-expect-error
      loader.setDataType?.(FloatType);
      if (extensions) extensions(loader);
    }
  );
  const texture: Texture = loaderResult;
  texture.mapping = EquirectangularReflectionMapping;

  useLayoutEffect(() => {
    const oldbg = scene ? scene.background : defaultScene.background;
    const oldenv = scene ? scene.environment : defaultScene.environment;
    if (scene) {
      scene.environment = texture;
      if (background) scene.background = texture;
    } else {
      defaultScene.environment = texture;
      if (background) defaultScene.background = texture;
    }
    return () => {
      if (scene) {
        scene.environment = oldenv;
        scene.background = oldbg;
      } else {
        defaultScene.environment = oldenv;
        defaultScene.background = oldbg;
      }
      // Environment textures are volatile, better dispose and uncache them
      texture.dispose();
    };
  }, [texture, background, scene]);

  return null;
}
