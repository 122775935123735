import EventEmitter from "eventemitter3";
import { createContext, useContext, useRef } from "react";

export const EventContext = createContext<EventEmitter>(undefined!);

// make EventEmitter in Context available
export const EventProvider = ({ children }) => {
  const EE = useRef(new EventEmitter()).current;

  return <EventContext.Provider value={EE}>{children}</EventContext.Provider>;
};

export function useEvents() {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("No EventContext found when calling useEvents.");
  }
  return context;
}
