import { evaluate } from "mathjs";
import { Vector3Tuple } from "three";

// methods to parse string to different types

export function parseVector3Tuple(value: string, input: number | string | boolean): Vector3Tuple | null {
  const array = new Array<number>();
  value
    .replace("[", "")
    .replace("]", "")
    .split(",")
    .forEach((element) => {
      element = element.trim();
      if (element.includes("inputValue")) {
        array.push(
          evaluate(element, {
            inputValue: input,
          })
        );
      } else {
        array.push(parseFloat(element));
      }
    });
  if (array.length === 3) {
    return array as Vector3Tuple
  }
  return null;
}

export function parseBooleanValue(value: string): boolean {
  return value === "true";
}

export function parseNumberValue(
  value: string,
  input: number | string | boolean
) {
  if (value.includes("inputValue")) {
    return evaluate(value, {
      inputValue: input,
    });
  }
  return parseFloat(value);
}

export function parseStringValue(
  value: string,
  input: number | string | boolean
) {
  return value.replace("inputValue", input.toString());
}
