import { useThree } from "@react-three/fiber";

const canvasToBlob = (canvas: HTMLCanvasElement) => {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject();
      }
    });
  });
};

export function useScreenshot() {
    const gl = useThree(state => state.gl)
    const canvas = gl.domElement;

    const getScreenshot = () => {
        return canvasToBlob(canvas)
    }

    return { getScreenshot }
}
