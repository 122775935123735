import {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { proxy, subscribe, useSnapshot } from "valtio";
import { useScene } from "../../context/SceneContext";
import {
  applyParameter,
  ConfigurationParameter,
} from "../models/ConfigurationParameter";
import { ConfigurationSchema } from "../models/ConfigurationSchema";
import { ObjectMap } from "../utils/ObjectMap";

export type ConfigurationContextType = {
  configurationSchema: ConfigurationSchema;
  configuration: {
    parameters: {
      [parameter: string]: string | number | boolean;
    };
  };
  updateConfiguration: (parameter: string, value: any) => void;
};

export const ConfigurationContext = createContext<ConfigurationContextType>(
  undefined!
);

type ConfigurationProviderType = {
  schema?: ConfigurationSchema;
};

const schemaToConfiguration = (schema: ConfigurationSchema) => {
  const configuration = {};
  for (const parameter of schema.parameters) {
    configuration[parameter.name] = parameter.default;
  }
  return configuration;
};

export const ConfigurationProvider: FunctionComponent<
  ConfigurationProviderType
> = ({ schema = { parameters: [] }, children }) => {
  const [configurationSchema, setConfigurationSchema] = useState(schema);
  // init configuration with default values
  const configuration = useRef(
    proxy({
      parameters: schemaToConfiguration(configurationSchema),
    })
  ).current;

  const api = useScene();
  const { modelLoaded, configurationLoaded } = useSnapshot(api.state);

  useEffect(() => {
    setConfigurationSchema(schema);
  }, [schema]);

  const applyConfiguration = useCallback(() => {
    const parameters = Object.keys(configuration.parameters);
    parameters.forEach((parameter) => {
      const parameterSchema = configurationSchema.parameters.find((p) => {
        return p.name === parameter;
      });
      if (!parameterSchema) {
        console.warn(
          `The parameter "${parameter}" is not present in the schema.`
        );
        return;
      }
      const value = configuration.parameters[parameter];
      applyParameter(parameterSchema, value, api);
    });
  }, [configurationSchema]);

  useEffect(() => {
    const unsubscribe = subscribe(configuration, () => {
      applyConfiguration();
    });
    return () => {
      unsubscribe();
    };
  }, [configurationSchema]);

  // useEffect(() => {
  //   configuration.parameters = schemaToConfiguration(configurationSchema);
  // }, [configurationSchema])

  useEffect(() => {
    if (modelLoaded) {
      // wait for configuration to load
      if (configurationSchema.parameters.length > 0) {
        const unsubscribe = subscribe(api.graph, () => {
          if (!configurationLoaded) {
            api.state.configurationLoaded = true;
            unsubscribe();
          }
        });
      } else {
        api.state.configurationLoaded = true;
      }
      applyConfiguration();
    }
  }, [modelLoaded]);

  const updateConfiguration = (
    parameter: string,
    value: string | boolean | number
  ) => {
    configuration.parameters[parameter] = value;
  };

  const exposed = {
    configurationSchema,
    configuration,
    updateConfiguration,
  };

  return (
    <ConfigurationContext.Provider value={exposed}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => {
  const context = useContext(ConfigurationContext);
  if (!context) {
    throw new Error(
      "No ConfigurationContext found when calling useConfiguration."
    );
  }
  return context;
};
