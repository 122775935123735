import {
  forwardRef,
  ReactNode,
} from "react";
import { ConfigurationGUI, ConnectUI } from "../configurator";
import { Scene } from "./Scene";
import { ConfigurationProvider } from "../configurator/context/ConfigurationContext";
import { RealityAPI, RealityAPIProvider } from "./RealityAPI";
import { RealityViewerProps } from "./RealityViewer";

const RealityCore = forwardRef<
  RealityAPI,
  RealityViewerProps & { children?: ReactNode }
>(
  (
    {
      backgroundColor,
      configurationSchema,
      gui = false,
      arButton = true,
      arButtonExternalLink,
      children,
      onClickIntersection,
      cameraControls = true,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className="reality-viewer"
        style={{ height: "100%", position: "relative", backgroundColor }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
            .reality-viewer canvas {
              cursor: ${cameraControls ? "grab" : "default"};
            }
            .reality-viewer canvas:active {
              cursor: ${cameraControls ? "grabbing" : "default"};
            }
          `,
          }}
        />
        <ConfigurationProvider
          schema={configurationSchema || { parameters: [] }}
        >
          <Scene {...props} cameraControls={cameraControls}>
            <RealityAPIProvider
              ref={ref}
              onClickIntersection={onClickIntersection}
            />
          </Scene>
          <ConfigurationGUI enabled={gui} />
          <ConnectUI />
          {children}
        </ConfigurationProvider>
      </div>
    );
  }
);

export { RealityCore };
