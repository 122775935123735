import { RealityAPI, RealityViewerProps } from "core";
import { forwardRef, Suspense } from "react";
import { useARLink } from "../hooks/useARLink";
import { ProjectLoader } from "./ProjectLoader";

export type RealityViewerWrapperProps = {
  projectId: string;
} & RealityViewerProps;

export const RealityViewerWrapper = forwardRef<RealityAPI, RealityViewerWrapperProps>((props, ref) => {
  
  return (
    <Suspense fallback={null}>
      <ProjectLoader {...props} ref={ref}/>
    </Suspense>
  );
})
