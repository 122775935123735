import React, { createRef, HTMLAttributes, Ref, RefObject } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import { Vector3Tuple } from "three";
import {
  MaterialConfig, RealityAPI,
} from "core";
import css from "bundle-text:./styles/reality-viewer.css";
import { RealityViewerWrapper } from "./components/RealityViewerWrapper";

const events = {
  onLoaded: "loaded",
};

// <reality-viewer> WebComponent that wraps the React Component
class RealityViewerWebComponent extends HTMLElement {
  static observedAttributes = ["value"];
  public observer: MutationObserver;
  public mountPoint: HTMLElement;

  private api: RefObject<RealityAPI> = createRef();

  public isLoaded = false;
  private events: any;
  constructor() {
    super();
    this.observer = new MutationObserver(() => this.update());
    this.observer.observe(this, { attributes: true });
    this.mountPoint = document.createElement("div");
    this.mountPoint.style.height = "100%";
    this.mountPoint.className = "reality-viewer";
    this.events = this.getEvents();
  }

  connectedCallback() {
    const shadowRoot = this.attachShadow({ mode: "open" });
    shadowRoot.appendChild(this.mountPoint);
    const style = document.createElement("style");
    style.innerText = css;
    shadowRoot.appendChild(style);
    this.mount();
  }

  disconnectedCallback() {
    this.unmount();
    this.observer.disconnect();
  }

  attributeChangedCallback() {
    this.update();
  }

  update() {
    // this.unmount();
    this.mount();
  }

  mount() {
    const props: any = {
      ...this.getProps(this.attributes),
      ...this.events,
    };

    // wrap React component
    const element = React.createElement(
      RealityViewerWrapper,
      { ...props, ref: this.api }
    );
    render(element, this.mountPoint);    
  }

  unmount() {
    unmountComponentAtNode(this);
  }

  getProps(attributes) {
    return [...attributes]
      .filter((attr) => attr.name !== "style")
      .map((attr) => this.convert(attr.name, attr.value))
      .reduce((props, prop) => ({ ...props, [prop.name]: prop.value }), {});
  }

  getEvents() {
    return Object.keys(events).reduce(
      (reducedEvents, key) => ({
        ...reducedEvents,
        [key]: (args) => {
          this.dispatchEvent(new CustomEvent(events[key], { ...args }));
        },
      }),
      {}
    );
  }

  // parse attribute values
  convert(attrName: string, attrValue) {
    let value = attrValue;
    attrName = attrName
      .split("-")
      .map((word, i) =>
        i === 0 ? word : word[0].toUpperCase() + word.slice(1)
      )
      .join("");
    if (attrValue === "true" || attrValue === "false")
      value = attrValue === "true";
    else if (!isNaN(attrValue) && attrValue !== "") value = +attrValue;
    else if (attrValue === "") value = true;
    else if (/^{.*}/.exec(attrValue)) value = JSON.parse(attrValue);
    return {
      name: attrName,
      value: value,
    };
  }

  getObjects() {
    return this.api.current?.getObjectNames();
  }

  getMaterials() {
    return this.api.current?.getMaterialNames();
  }

  updateMaterial(name: string, config: MaterialConfig) {
    this.api.current?.updateMaterial(name, config);
  }

  setScale(name: string, scale: Vector3Tuple) {
    this.api.current?.setScale(name, scale);
  }

  setPosition(name: string, position: Vector3Tuple) {
    this.api.current?.setPosition(name, position);
  }

  setRotation(name: string, rotation: Vector3Tuple) {
    this.api.current?.setRotation(name, rotation);
  }

  setVisible(name: string, visible: boolean) {
    this.api.current?.setVisible(name, visible);
  }

  setMorphTargetInfluence(
    name: string,
    morphTarget: string,
    influence: number
  ) {
    this.api.current?.setMorphTargetInfluence(name, morphTarget, influence);
  }

  updateConfiguration(name: string, value: string | number | boolean) {
    this.api.current?.updateConfiguration(name, value);
  }

  triggerAR() {
    // eventDispatcher.dispatchEvent({ type: "triggerAR" });
  }

  getCameraPosition() {
    return this.api.current?.getCameraPosition();
  }
}

customElements.define("reality-viewer", RealityViewerWebComponent);
