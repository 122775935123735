
// a generic overlay with a message
export function Overlay({ message = "Project not found!" }) {
  return (
    <div className="not-found-overlay-wrapper">
      <div className="not-found-overlay">
        <h1>reality.link</h1>
        <p>{ message }</p>
      </div>
    </div>
  );
}
