import {
  forwardRef,
  ReactNode,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Icosahedron,
  OrbitControls,
  PerspectiveCamera,
  Stats,
} from "@react-three/drei";
import RealityModel, { ClickIntersection } from "./RealityModel";
import { ErrorBoundary } from "react-error-boundary";
import { ARCanvas } from "./ARCanvas";
import { ARPlacement } from "./ARPlacement";
import { Stage } from "./Stage";
import { EffectComposer, SMAA } from "@react-three/postprocessing";
import { PresetsType } from "@react-three/drei/helpers/environment-assets";
import { Camera, Vector3Tuple } from "three";
import { BakeShadows } from "./BakeShadows";
import { SceneProvider } from "../context/SceneContext";
import { ConfigurationSchema } from "../configurator/models/ConfigurationSchema";
import { ConfigurationGUI, ConnectUI } from "../configurator";
import { EventProvider } from "../context/EventContext";
import { Scene } from "./Scene";
import { ConfigurationProvider } from "../configurator/context/ConfigurationContext";
import { RealityAPI, RealityAPIProvider } from "./RealityAPI";
import { Annotation } from "../annotations/models/Annotation";
import { RealityCore } from "./RealityCore";

export type RealityViewerProps = {
  id: string;
  src: string;
  onLoaded?: () => void;
  onError?: (error: Error) => void;
  gui?: boolean;
  autoLoad?: boolean;
  autoRotate?: boolean;
  autoRotateSpeed?: number;
  arButton?: boolean;
  arButtonExternalLink?: string;
  cameraControls?: boolean;
  polarAngle?: number;
  minPolarAngle?: number;
  maxPolarAngle?: number;
  azimuthAngle?: number;
  minAzimuthAngle?: number;
  maxAzimuthAngle?: number;
  distance?: number;
  minDistance?: number;
  maxDistance?: number;
  fov?: number;
  background?: boolean;
  environmentIntensity?: number;
  environment?: PresetsType;
  contactShadow?: boolean;
  contactShadowOpacity?: number;
  lightIntensity?: number;
  preserveDrawingBuffer?: boolean;
  backgroundColor?: string;
  initialCameraPosition?: Vector3Tuple;
  shadows?: boolean;
  shadowBias?: number;
  stats?: boolean;
  onModelLoaded?: () => void;
  onStartedLoading?: () => void;
  configurationSchema?: ConfigurationSchema;
  annotations?: Annotation[];
  onClickIntersection?: (clickIntersection: ClickIntersection) => void;
  encrypted?: boolean;
};

const RealityViewer = forwardRef<
  RealityAPI,
  RealityViewerProps & { children?: ReactNode }
>((props, ref) => {
  return (
    <EventProvider>
      <SceneProvider autoLoad={props.autoLoad}>
        <RealityCore ref={ref} {...props}>
          {props.children}
        </RealityCore>
      </SceneProvider>
    </EventProvider>
  );
});

export { RealityViewer };
