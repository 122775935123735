import { useEffect, useLayoutEffect } from "react";
import {
  Group,
  Material,
  Mesh,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Object3D,
} from "three";
import { useScene } from "../context/SceneContext";
import { RealityMaterial } from "./RealityMaterial";
import { useSnapshot } from "valtio";
import { RealityTextureMaterial } from "./RealityTextureMaterial";

function isArrayOfMaterials(material: any): material is MeshStandardMaterial[] {
  return (
    Array.isArray(material) &&
    material.every((item) => item instanceof MeshStandardMaterial)
  );
}

export function RealityNode({ object, ...rest }) {
  const { addObject, graph } = useScene();

  useEffect(() => {
    addObject(object);
  }, []);

  const objectsState = useSnapshot(graph.objects);
  const materialsState = useSnapshot(graph.materials);
  const props = objectsState[object.name];

  // TODO: Helper Hook
  let morphTargetInfluences =
    (object instanceof Mesh && object.morphTargetInfluences) || [];

  if (object instanceof Mesh && object.morphTargetInfluences) {
    morphTargetInfluences = morphTargetInfluences.map((value, index) => {
      let morphTargetForCurrentIndex: string | undefined = undefined;
      if (object.morphTargetDictionary) {
        for (const morphTargetName of Object.keys(
          object.morphTargetDictionary
        )) {
          if (object.morphTargetDictionary[morphTargetName] === index) {
            morphTargetForCurrentIndex = morphTargetName;
          }
        }
      }
      if (morphTargetForCurrentIndex && props?.morphTargets) {
        return props.morphTargets[morphTargetForCurrentIndex] || 0;
      }
      return 0;
    });
  }

  const objectChildren = object.children.map((child) => (
    <RealityNode object={child} key={child.uuid} />
  ));

  let materials: Array<MeshStandardMaterial | MeshPhysicalMaterial> = [];
  if (
    object.material instanceof MeshStandardMaterial ||
    object.material instanceof MeshPhysicalMaterial
  ) {
    materials.push(object.material);
  }
  if (isArrayOfMaterials(object.material)) {
    materials.push(...object.material);
  }

  return (
    <primitive
      object={object}
      {...props}
      {...rest}
      morphTargetInfluences={morphTargetInfluences}
      castShadow
      receiveShadow
    >
      {materials.map((material) => {
        const materialProps = materialsState[material.name];
        if (materialProps?.mapURL != null) {
          return (
            <RealityTextureMaterial
              key={material.uuid}
              mapURL={materialProps.mapURL}
              material={material}
            />
          );
        } else {
          return <RealityMaterial key={material.uuid} material={material} />;
        }
      })}
      {objectChildren}
    </primitive>
  );
}
