import {
  BufferAttribute,
  InterleavedBufferAttribute,
  Object3D,
  Vector3,
} from "three";

export const getNormalizedComponentScale = (
  buffer: BufferAttribute | InterleavedBufferAttribute
) => {
  if (!buffer.normalized) {
    return 1;
  }

  const array: ArrayLike<number> = buffer.array;
  if (array instanceof Int8Array) {
    return 1 / 127;
  } else if (array instanceof Uint8Array) {
    return 1 / 255;
  } else if (array instanceof Int16Array) {
    return 1 / 32767;
  } else if (array instanceof Uint16Array) {
    return 1 / 65535;
  }
  return 1;
};

export function reduceVertices<T>(
  model: Object3D,
  func: (value: T, vertex: Vector3) => T,
  initialValue: T
): T {
  let value = initialValue;
  const vertex = new Vector3();
  model.traverse((object: any) => {
    let i, l;

    object.updateWorldMatrix(false, false);

    const geometry = object.geometry?.clone();    

    if (object.visible && object.parent.visible && geometry !== undefined) {
      const morphInfluences = object.morphTargetInfluences;
      if (morphInfluences) {        
        // loop through all attributes
        for (const attribute of Object.keys(geometry.morphAttributes)) {
          const data = geometry.attributes[attribute].array;
          const updatedArray = new Float32Array(data.length);
          for (let vIndex = 0; vIndex < data.length; vIndex++) {
            const originalNumber = data[vIndex];
            let updatedNumber = originalNumber;
            for (const [index, morphAttribute] of geometry.morphAttributes[
              attribute
            ].entries()) {
              const influence = morphInfluences[index];
              if (influence === 0) continue;
              updatedNumber += morphAttribute.array[vIndex] * influence;
            }
            updatedArray[vIndex] = updatedNumber;
          }
          geometry.attributes[attribute] = new BufferAttribute(updatedArray, 3);
        }
      }
      if (geometry.isGeometry) {
        const vertices = geometry.vertices;

        for (i = 0, l = vertices.length; i < l; i++) {
          vertex.copy(vertices[i]);
          vertex.applyMatrix4(object.matrixWorld);
          value = func(value, vertex);
        }
      } else if (geometry.isBufferGeometry) {
        const { position } = geometry.attributes;

        if (position !== undefined) {
          const scale = getNormalizedComponentScale(position);

          for (i = 0, l = position.count; i < l; i++) {
            vertex.fromBufferAttribute(position, i);
            vertex.multiplyScalar(scale);
            vertex.applyMatrix4(object.matrixWorld);

            value = func(value, vertex);
          }
        }
      }
    }
  });
  return value;
}
