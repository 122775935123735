export const ARGlyph = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61 70">
    <g fill="currentColor" fillRule="nonzero">
      <path d="M38.85 8.5c-.25 0-.5-.06-.72-.19L30.5 3.89l-7.66 4.42a1.44 1.44 0 1 1-1.44-2.5L29.79 1c.44-.26 1-.26 1.44 0l8.35 4.83a1.44 1.44 0 0 1-.72 2.69M30.5 46.49A1.44 1.44 0 0 1 29.06 45v-9.62a1.44 1.44 0 0 1 2.88 0V45c0 .8-.64 1.44-1.44 1.44M1.8 29.89c-.8 0-1.44-.64-1.44-1.44v-9.66a1.44 1.44 0 1 1 2.88 0v9.66a1.44 1.44 0 0 1-1.44 1.44M59.17 29.89c-.8 0-1.44-.64-1.44-1.44v-9.66a1.44 1.44 0 1 1 2.88 0v9.66a1.44 1.44 0 0 1-1.44 1.44M59.18 53.41c-.78 0-1.42-.63-1.44-1.41v-9.7a1.44 1.44 0 1 1 2.88 0V52c0 .8-.64 1.44-1.44 1.44v-.03ZM1.8 53.41c-.78 0-1.42-.63-1.44-1.41v-9.7a1.44 1.44 0 0 1 2.88 0V52c0 .8-.64 1.44-1.44 1.44"></path>
      <path d="M30.5 14.2c-.8 0-1.44-.64-1.44-1.44V2.23a1.44 1.44 0 0 1 2.88 0v10.53c0 .8-.64 1.44-1.44 1.44M30.5 70c-.8 0-1.44-.64-1.44-1.44v-9.65a1.44 1.44 0 0 1 2.88 0v9.66c0 .8-.65 1.43-1.44 1.43"></path>
      <path d="M30.5 70c-.25 0-.5-.07-.72-.19L21.38 65a1.44 1.44 0 0 1 1.44-2.5l7.69 4.45 7.58-4.39A1.44 1.44 0 0 1 39.53 65l-8.3 4.81c-.22.13-.47.2-.72.19M30.5 36.82c-.25 0-.5-.07-.72-.19l-8.38-4.84a1.44 1.44 0 0 1 1.44-2.5l7.66 4.42 7.63-4.42a1.44 1.44 0 0 1 1.44 2.49l-8.35 4.84c-.22.13-.47.2-.72.19M50.81 25.07a1.44 1.44 0 0 1-.72-2.69l8.36-4.83A1.44 1.44 0 0 1 59.89 20l-8.36 4.83c-.22.13-.47.2-.72.19"></path>
      <path d="M59.17 20.23c-.25 0-.5-.06-.72-.19l-8.37-4.84a1.44 1.44 0 0 1 1.44-2.5l8.37 4.84a1.44 1.44 0 0 1-.72 2.69M10.16 25.07c-.25 0-.5-.06-.72-.19L1.08 20a1.44 1.44 0 0 1 1.44-2.5l8.37 4.83a1.44 1.44 0 0 1-.72 2.69"></path>
      <path d="M1.8 20.23a1.44 1.44 0 0 1-.72-2.69l8.36-4.82a1.44 1.44 0 0 1 1.44 2.5L2.52 20c-.22.12-.47.19-.72.19M50.8 58.24a1.44 1.44 0 0 1-.72-2.69l8.38-4.83a1.44 1.44 0 0 1 1.44 2.5l-8.38 4.83c-.22.13-.47.2-.72.19"></path>
      <path d="M59.17 53.41c-.25 0-.5-.06-.72-.19l-8.37-4.83a1.44 1.44 0 0 1 1.44-2.5l8.37 4.83a1.44 1.44 0 0 1-.72 2.69M10.16 58.25c-.25 0-.5-.06-.72-.19l-8.37-4.84a1.44 1.44 0 0 1 1.44-2.5l8.37 4.84a1.44 1.44 0 0 1-.72 2.69"></path>
      <path d="M1.8 53.41a1.44 1.44 0 0 1-.72-2.69l8.35-4.84a1.44 1.44 0 0 1 1.44 2.5l-8.35 4.83c-.22.13-.47.2-.72.19"></path>
      <path
        d="M1.8 19.61a.82.82 0 0 1-.41-1.53l28.7-16.57a.82.82 0 0 1 .82 1.42L2.21 19.5a.82.82 0 0 1-.41.11M1.8 52.79a.82.82 0 0 1-.41-1.53l57.37-33.18a.82.82 0 0 1 .82 1.42L2.21 52.68a.82.82 0 0 1-.41.11"
        opacity=".2"
      ></path>
      <path
        d="M59.17 19.61a.82.82 0 0 1-.41-.11L30.09 2.94a.82.82 0 0 1 .82-1.42l28.67 16.56a.82.82 0 0 1-.41 1.53M59.17 52.79a.81.81 0 0 1-.41-.11L1.39 19.5a.82.82 0 0 1 .82-1.42l57.38 33.18a.82.82 0 0 1-.41 1.53M30.5 69.4a.82.82 0 0 1-.41-.11L1.39 52.68a.82.82 0 0 1 .82-1.42l28.7 16.6a.82.82 0 0 1-.41 1.53"
        opacity=".2"
      ></path>
      <path
        d="M30.51 69.4a.82.82 0 0 1-.41-1.53l28.66-16.61a.82.82 0 0 1 .82 1.42L30.92 69.29a.82.82 0 0 1-.41.11M1.8 52.79A.82.82 0 0 1 1 52V18.79a.82.82 0 1 1 1.64 0V52c0 .45-.37.82-.82.82"
        opacity=".2"
      ></path>
      <path
        d="M59.18 52.79a.82.82 0 0 1-.82-.82V18.79a.82.82 0 1 1 1.64 0V52c0 .45-.37.82-.82.82M30.5 69.3a.82.82 0 0 1-.82-.82V2.23a.82.82 0 1 1 1.64 0v66.25c0 .45-.37.82-.82.82"
        opacity=".2"
      ></path>
    </g>
  </svg>
);
