import { useCallback, useEffect, useState } from "react";
import { ARGlyph } from "../icons/ARGlyph";
import { Cross } from "../icons/Cross";
import { QRCode } from "react-qrcode";
import { useAR } from "core";
import { useARLink } from "../hooks/useARLink";

type Props = {
  enabled: boolean;
  projectId: string;
};

// AR Button that starts AR or opens an overlay with QR code
export function ARButton({
  enabled,
  projectId
}: Props) {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const { triggerAR } = useAR()
  const externalARLink = useARLink(projectId);

  const onARNotSupported = useCallback(() => {
    setOverlayVisible(true);
  }, [])

  const onClick = () => {
    triggerAR(onARNotSupported)
  };

  const mailTo = () => {
    window.location.href =
      "mailto:?subject=" +
      encodeURIComponent(document.title) +
      "&body=" +
      encodeURIComponent(externalARLink);
  };

  return (
    <>
      {overlayVisible ? (
        <div className="qr-overlay-wrapper">
          <div className="qr-overlay">
            <div className="qr-code">
              <QRCode value={externalARLink} />
            </div>
            <p>
              Scan the QR code with your smartphone or tablet to view the object
              in augmented reality.
            </p>
            <button
              style={{ background: "#333", color: "#FFF" }}
              className="share"
              onClick={() => mailTo()}
            >
              Share link via e-mail
            </button>
            <button
              className="qr-overlay-close"
              onClick={() => setOverlayVisible(false)}
            >
              <Cross />
            </button>
          </div>
        </div>
      ) : null}
      {enabled && (
        <div className="ar-button-container">
          <button
            className="ar-button"
            onClick={onClick}
          >
            <ARGlyph
              className="ar-glyph"
            />
            <span>Show in AR</span>
          </button>
        </div>
      )}
    </>
  );
}
