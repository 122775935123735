import { Box, Plane } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useHitTest, useXR, useXRFrame } from "@react-three/xr";
import { useEffect, useRef, useState } from "react";
import { Group, Matrix4, Mesh, XRFrame, XRInputSourceEvent } from "three";

const matrix4 = new Matrix4();
export function ARPlacement({ children }) {
  const { isPresenting } = useXR();
  const [isPlacing, setIsPlacing] = useState(true);
  const model = useRef<Group>();
  const showModel = (!isPlacing && isPresenting) || !isPresenting

  // apply hit test transformation to model when placing is activated
  useHitTest((hitMatrix, hit) => {
    if (isPresenting && isPlacing) {
      if (model.current) {
        if (hitMatrix !== matrix4) {
          hitMatrix.decompose(
            model.current.position,
            model.current.quaternion,
            model.current.scale
          );
          setIsPlacing(false);
        }
      }
    }
  });

  useEffect(() => {
    if (!isPresenting) {
      model.current?.position.set(0, 0, 0);
    }
  }, [isPresenting]);

  return (
    <>
      <group ref={model} visible={showModel}>
        {children}
      </group>
    </>
  );
}
