import { ConfigurationSchema, RealityViewerProps, Annotation } from "core";
import { suspend } from "suspend-react";

interface ProjectURLsResponse {
  model: string | undefined;
  configuration: ConfigurationSchema | undefined;
  options: Omit<RealityViewerProps, "id" | "src">;
  annotations: Annotation[]
}

// fetch project details from database 
export async function fetchProjectDetails(projectId: string) {
  return new Promise<ProjectURLsResponse>(async (resolve, reject) => {
    try {
      const { error, model, configuration, options, annotations } = await fetch(
        `https://studio.reality.link/api/project/${projectId}`
      ).then<{
        model: string;
        error?: string;
        configuration: any;
        options: any;
        annotations: any;
      }>((res) => res.json());
      if (error != null) {
        throw error;
      }
      if (model) {
        resolve({
          model,
          configuration,
          options,
          annotations
        });
      } else {
        throw null;
      }
    } catch (error) {
      resolve({
        model: undefined,
        configuration: undefined,
        options: {},
        annotations: []
      });
    }
  });
}

export function useProjectDetails(projectId: string) {
  return suspend(fetchProjectDetails, [projectId]);
}
