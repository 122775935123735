// Start Screen that uses the thumbnail of the project
export function StartScreen({ projectId, visible, onPlay }) {
  return (
    <div
      className="not-found-overlay-wrapper start-screen"
      style={{
        background: "transparent",
        opacity: visible ? "100%" : "0%",
        transition: "opacity 200ms ease-in-out",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        pointerEvents: visible ? "auto" : "none"
      }}
    >
      <div
        style={{
          background: `url(https://quunqypazqosotdcxsjj.supabase.co/storage/v1/object/public/project-bucket/${projectId}/thumbnail.png)`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          filter: "blur(4px)",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      ></div>
      <div style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="play-icon"
        onClick={onPlay}
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
          clipRule="evenodd"
        />
      </svg>
      </div>
    </div>
  );
}
